<div class="col-sm-12 mb-3">
  <div class="pro-derails-body">
    <div class="order-d-card white-box derails-cols">
      <h5>List of Reviewers</h5>

      <div class="fil-table-body mr-t20">
        <div class="cus-table table-responsive">
          <dx-data-grid
            [columnMinWidth]="50"
            class="color-table"
            id="gridContainer"
            [dataSource]="reviewerArr"
            [remoteOperations]="false"
            [allowColumnReordering]="true"
            [rowAlternationEnabled]="true"
            [showBorders]="true"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-group-panel [visible]="false"></dxo-group-panel>
            <dxo-grouping [autoExpandAll]="false"></dxo-grouping>

            <dxi-column
              alignment="left"
              [width]="200"
              dataField="reviewer"
              [allowSorting]="false"
              caption="Reviewer"
            >
            </dxi-column>

            <dxi-column
              alignment="left"
              [width]="200"
              dataField="department"
              [allowSorting]="false"
              caption="Department"
            ></dxi-column>

            <dxi-column
              alignment="left"
              [width]="200"
              dataField="approved_date_and_time"
              dataType="date"
              [allowSorting]="false"
              caption="Approved Date & Time"
            ></dxi-column>

            <dxi-column
              alignment="left"
              [width]="150"
              dataField="status"
              [allowSorting]="false"
              caption="Status"
              cssClass="no-wrap-txt"
            ></dxi-column>

            <dxi-column
              cssClass="no-wrap-txt"
              alignment="left"
              dataField="comment"
              [width]="180"
              [allowSorting]="false"
              caption="Comments"
            ></dxi-column>

            <dxi-column
              type="buttons"
              *ngIf="!is_history"
              cssClass="actions"
              cellTemplate="action"
              caption="Action"
            >
            </dxi-column>

            <div *dxTemplate="let d of 'action'">
              <div class="chart-cell">
                <img
                  (click)="editComment(d)"
                  *ngIf="userData.id === d.data.reviewer_id"
                  src="assets/images/edit-icon.png"
                  alt="edit-icon"
                  class="cursor"
                />

                <img
                  class="cursor ml-2"
                  (click)="deleteComment(d)"
                  *ngIf="userData.id === d.data.reviewer_id"
                  src="assets/images/delete-icon.png"
                  alt="delete-icon"
                />
              </div>
            </div>
          </dx-data-grid>
        </div>
        <!-- <div class="cus-pagination mr-t20" *ngIf="total_records > 10">
          <nav>
            <ul class="pagination justify-content-center">
              <li (click)="onPrevNext('prev')" class="page-item disabled mr-r10">
                <a class="page-link" href="javascript:void(0);">
                  <svg
                    width="13"
                    height="13"
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.47397 6.10116L7.15597 3.41916L6.44897 2.71216L2.55997 6.60116L6.44897 10.4902L7.15597 9.78316L4.47397 7.10116H10.56V6.10116H4.47397Z"
                      fill="#064584"
                    />
                  </svg>
                  Previous
                </a>
              </li>

              <li
                class="page-item"
                [ngClass]="{ active: activePage === item.pageNumber }"
                *ngFor="let item of pages"
                (click)="onClickPage(item.pageNumber, item.skipRecord)"
              >
                <a class="page-link" href="javascript:void(0);">{{
                  item.pageNumber
                }}</a>
              </li>

              <li class="page-item mr-l10" (click)="onPrevNext('next')">
                <a class="page-link" href="javascript:void(0);">
                  Next
                  <svg
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.526 4.10116L3.844 1.41916L4.551 0.712158L8.44 4.60116L4.551 8.49016L3.844 7.78316L6.526 5.10116H0.440002V4.10116H6.526Z"
                      fill="#064584"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </nav>
        </div> -->
      </div>
    </div>
  </div>
</div>
<div
  class="col-sm-12 mb-3"
  *ngIf="reviewerArr && reviewerArr.length > 0 && showSummary"
>
  <div class="pro-details-info mr-t10">
    <div class="pro-derails-body">
      <div class="order-d-card p-3 white-box derails-cols">
        <div class="row">
          <div class="col-sm-12">
            <!-- <form [formGroup]="summaryForm"> -->
            <h5 class="text-muted summary-title mb-2">Summary :</h5>
            <div class="static-info box-input m-0">
              <div class="form-group m-0">
                <div class="summary-list" [innerHtml]="summaryStr"></div>
              </div>
            </div>
            <!-- </form> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
