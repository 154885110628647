import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private router:Router, private localStorageService: LocalStorageService) { }

  /**
   * @description - Can activate function to check if user logged in or not and activate route
   * @returns 
   */
  canActivate(){
    const data = this.localStorageService.getData('isUserLogin');
    const isUserLogin = data === 'true' ? true : false;
    if(isUserLogin){
      return true;
    }
    this.router.navigate(['/auth/login']);
    return false;    
  }
}
