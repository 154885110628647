import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AirLogisticsService {
  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  /**
   * @description - Http call to get the order list
   * @returns
   */
  getOrderList(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/airLogistics/getProjectsForAirLogistics?searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the order history fagement screen
   * @returns
   */
  getAlHistory(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/airLogistics/getAlHistory?searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to allocate product
   * @param orderData - order data
   */
  scanBarcode(productData: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/airLogistics/scanBarcode`, productData, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to allocate product
   * @param orderData - order data
   */
  deleteProduct(product_id: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .delete(
        `${environment.apiUrl}/airLogistics/deleteProductFromFulfillment/${product_id}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the order details
   * @param orderId - order id
   * @returns
   */
  getOrderDetails(orderId: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(`${environment.apiUrl}/order/getOrderDetails?orderId=${orderId}`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the product details
   * @returns
   */
  getProductById(barCode: any, orderId: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/airLogistics/getProductDetailById?barCode=${barCode}&orderId=${orderId}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the fulfill product list for canister
   * @returns
   */
  getFulfillList(data: any) {
    let url = '';
    if (data.canisterSubTypeId) {
      url = `?productId=${data.productId}&orderId=${data.orderId}&canisterSubtypeId=${data.canisterSubTypeId}`;
    } else if (data.manifoldSubTypeId) {
      url = `?productId=${data.productId}&orderId=${data.orderId}&manifoldSubTypeId=${data.manifoldSubTypeId}`;
    } else {
      url = `?productId=${data.productId}&orderId=${data.orderId}`;
    }
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(`${environment.apiUrl}/airLogistics/listFulfillment${url}`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the product master data
   * @returns
   */
  getProductMasterData() {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(`${environment.apiUrl}/order/getMasterData`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to create order fulfillment
   * @param orderData - order data
   */
  createOrderFulfillment(orderData: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(
        `${environment.apiUrl}/airLogistics/createOrderFulfillment`,
        orderData,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the product master data
   * @returns
   */
  getChecklist(checklist_id: number) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/airLogistics/getChecklists?checklistId=${checklist_id}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to verify checklist
   * @param data - checklist verification data
   * @returns
   */
  verifyChecklist(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/airLogistics/verifyCheckList`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to approve and ship the order
   * @param data - order data
   * @returns
   */
  readyToShip(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/airLogistics/acceptAndReadyToship`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the list of unused products
   * @returns
   */
  getUnusedList(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/airLogistics/getUnusedProducts?searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to update the ununsed product status
   * @param data - data to be send
   */
  updateUnusedPoduct(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/airLogistics/updateUnusedPoduct`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the list of batch for order to clean
   * @param data - param data
   * @returns
   */
  getCleaningBatches(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/airLogistics/getCleaningBatches?searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the products for batch
   * @param productId - product id
   * @returns
   */
  getProductForBatch(productTypeId: number, certification: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/airLogistics/getBatchesForProducts?productTypeId=${productTypeId}&Certification=${certification}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to create batch
   * @param data - product data
   */
  createBatch(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/airLogistics/createBatch`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the cleaning details of the batch
   * @param batchId - batch id
   * @returns
   */
  getCleaningDetails(batchId: number) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/airLogistics/getParticularBatchForIdentificationSelection?batchId=${batchId}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to complete cleaning
   * @param data - identification data
   * @returns
   */
  completeForCleaning(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/airLogistics/completeCleaningBatch`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to start cleaning
   * @param data - start process data
   * @returns
   */
  startOrEndProcess(data: any, flag: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    const url =
      flag === 'start' ? `startCleaningProcess` : `endCleaningProcess`;
    return this.httpClient
      .post(`${environment.apiUrl}/airLogistics/${url}`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to end cleaning
   * @param data - end process data
   * @returns
   */
  endProcess(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/airLogistics/endCleaningProcess`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to approve items for cleaning
   * @param data - identification data
   * @returns
   */
  approveForCleaning(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/airLogistics/approveCleaningBatch`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the ready to store batch list
   * @param data - param data
   * @returns
   */
  getReadyToStoreBatches(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/airLogistics/getReadyToStoreList?skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get particular batch details
   * @param batchId - batch id
   * @returns
   */
  getBatchDetail(batchId: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/airLogistics/getListOfProductsOfParticularBatch?batchId=${batchId}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to update the inventory
   * @param batchData - batch data
   * @returns
   */
  updateInventory(batchData: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(
        `${environment.apiUrl}/airLogistics/approveCleaningBatch`,
        batchData,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the product details of scanned barcode
   * @returns
   */
  getScannedProduct(barCode: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/airLogistics/scanBarcodeForReadyToStore?barcode=${barCode}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the product details of scanned barcode for create batch
   * @returns
   */
  scanProductForBatch(barCode: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/airLogistics/scanBarcodeForCreateBatch?barcode=${barCode}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the order to clean history
   * @returns
   */
  getOrderToCleanHistory(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/airLogistics/getOrderToCleanHistory?searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the ready to store history
   * @returns
   */
  getReadyToStoreHistory(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/airLogistics/getReadyToStoreHistory?searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to update vaccum process for idenfication canister
   * @param data - request body data
   * @returns
   */
  updateIdentificationVaccum(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(
        `${environment.apiUrl}/airLogistics/updateVacuumProcessForIdentification`,
        data,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the order pdf
   * @returns
   */
  getOrderPdf(orderId: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/airLogistics/orderDetailPdf?orderId=${orderId}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the other documents list
   * @param batchId - batch id
   * @returns
   */
  getOtherDocuments(batchId: number) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/airLogistics/viewOtherDocuments?batchId=${batchId}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to send new bottle order request
   * @param data - request data
   * @returns
   */
  sendBottleOrderRequest(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/airLogistics/sendShipmentLink`, data, {
        headers: headers,
      })
      .toPromise();
    // return this.httpClient
    //   .post(`${environment.apiUrl}/airLogistics/sendShipmentLink`, data, {
    //     headers: headers,
    //   })
    //   .toPromise();
  }
}
