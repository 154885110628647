import { Component, OnInit,EventEmitter, Output, TemplateRef, ViewChild, OnDestroy, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'app-order-confirm',
  templateUrl: './order-confirm.component.html',
  styleUrls: ['./order-confirm.component.scss']
})
export class OrderConfirmComponent implements OnInit, OnDestroy {
  modalRef!: BsModalRef;
  modalSub!: any;
  subscription!: Subscription;
  status!: Subscription;
  @ViewChild('orderConfirm', { static: true }) templateEl!: TemplateRef<any>;
  @Output() close = new EventEmitter<boolean>();
  @Output() choice = new EventEmitter<string>();
  @Input() message !:string;
  @Input() header !:string;
  alertMessage: any = '';
  show = false;

  public onClose!: Subject<boolean>;
  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
    console.log('alert msg', this.message);
    if(this.message && this.message !== undefined){
      this.alertMessage = this.message;
    }
    // this.initModal();
    // this.subscription = this.alertService.orderConfirmState.subscribe(
    //   (state: any) => {
    //     console.log('order confirm', state);
    //     this.initModal();
    //     this.show = state.show;
    //     this.alertMessage = state.message;
    //     this.header = state.header;
    //   }
    // );
  }

  /**
   * @description - Function to initialize the modal reference variable
   */
   initModal() {
    this.modalRef = this.modalService.show(this.templateEl, {
      class:
        'alert-modal dialog513 modal-dialog-centered modal-dialog-scrollable',
    });
    this.modalSub = this.modalRef.onHidden?.subscribe((val: any) => {
      this.close.emit(true);
    });
  }

  hide() {
    // this.modalRef.hide();
    this.close.emit();
  }

  ngOnDestroy(): void {
    // this.alertService.orderConfirmStatus.unsubscribe();
    // this.subscription.unsubscribe();
  }

  /**
   * @description - Function to emit true if yes selected
   */
  yes() {
    console.log('yes');
    // this.alertService.orderConfirmStatus.next(true);
    this.close.emit(true);
    // this.modalRef.hide();
  }

  /**
   * @description - Function to emit true if yes selected
   */
  cancel() {
    console.log('cancel');
    // this.alertService.orderConfirmStatus.next(false);
    this.close.emit(false);
    // this.modalRef.hide();
  }

}
