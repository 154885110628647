<ng-template #addComment>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
      {{ isEdit ? "Edit" : "Add" }} Comment
    </h5>
    <button
      (click)="modalRef.hide()"
      type="button"
      class="close m-close-btn"
      data-dismiss="modal"
      aria-label="Close"
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.11107 5.36626L12.0611 0.41626L13.4751 1.83026L8.52507 6.78026L13.4751 11.7303L12.0611 13.1443L7.11107 8.19426L2.16107 13.1443L0.74707 11.7303L5.69707 6.78026L0.74707 1.83026L2.16107 0.41626L7.11107 5.36626Z"
          fill="white"
        />
      </svg>
    </button>
  </div>
  <form [formGroup]="commentForm">
    <div class="modal-body plan-modal" *ngIf="userData">
      <div class="plan-form-wrap mx-auto mt-4">
        <div class="row align-items-baseline">
          <div class="col-sm-12 mb-3">
            <div class="static-info static-data box-input">
               <h5>Employee Name: {{userData.first_name+' '+ userData.last_name}}</h5>
            </div>
          </div>
          <div class="col-sm-12 mb-3">
            <div class="static-info static-data box-input">
              <h5>Department: {{userData.designation}}</h5>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="static-info box-input">
              <h5>Comment: </h5>
            </div>
          </div>
          <div class="col-12 mr-b20">
            <div class="static-info box-input">
              <div class="form-group justify-content-between mr-b10">
                <mat-form-field appearance="standard" class="w-100">
                  <textarea
                    matInput
                    formControlName="comment"
                    class="form-control mt-2"
                    rows="5"
                    [maxlength]="100"
                    placeholder="Enter Comment"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
            <mat-error
              *ngIf="
                (commentForm.controls.comment.touched || !isFormSubmitted) &&
                commentForm.controls.comment.errors?.required
              "
            >
              Please provide comment
            </mat-error>
          </div>
        </div>
      </div>
      <div class="p-mbtn mr-t40 text-right">
        <button
          (click)="submitCommentForm()"
          [disabled]="disableSubmit"
          type="submit"
          class="blue-btn h32 pd-l20 pd-r20 mr-r20"
        >
          {{ isEdit ? "Edit" : "Add" }}
        </button>

        <button
          type="button"
          (click)="modalRef.hide()"
          class="blue-line-btn blue-line-ltext h32 pd-l10 pd-r10"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</ng-template>
