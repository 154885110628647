import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/services/alert/alert.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { ProjectScopingService } from 'src/app/services/project-scoping/project-scoping.service';

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss'],
})
export class AddCommentComponent implements OnInit {
  modalRef!: BsModalRef;
  modalSub!: any;
  @Output() close = new EventEmitter<boolean>();
  @Output() commentEvent = new EventEmitter<boolean>();
  today = new Date();
  deptArr: any;
  empArr: any;
  commentForm!: FormGroup;
  isFormSubmitted = true;
  @ViewChild('addComment', { static: true }) templateEl!: TemplateRef<any>;
  action: string = 'addReviewer';
  disableSubmit: boolean = false;
  @Input() commentData: any;
  @Input() isEdit: boolean = false;
  @Input() projectId:any;
  userData:any;
  constructor(
    private formBuilder: FormBuilder,
    private projectScopingService: ProjectScopingService,
    private alertService: AlertService,
    private modalService: BsModalService,
    private localStorageService:LocalStorageService
  ) {}

  ngOnInit(): void {
    this.initModal();
    this.getUserDetails();
    this.initcommentForm();
  }

  getUserDetails(){
    const json:any = this.localStorageService.getData('userDetails');
    this.userData = JSON.parse(json);
  }

  /**
   * @description - Function to initialize the modal reference variable
   */
  initModal() {
    this.modalRef = this.modalService.show(this.templateEl, {
      class: 'modal-dialog-centered dashboard-modal',
    });
    this.modalSub = this.modalRef.onHidden?.subscribe((val: any) => {
      this.close.emit(true);
    });
  }

  /**
   * @description - Function to initialize the plan form with form control
   */
  initcommentForm() {
    this.commentForm = this.formBuilder.group({
      comment: ['', [Validators.required]],
    });
    if (this.isEdit) {
      this.action = 'updateReview';
      this.patchFormValues();
    }
  }

  patchFormValues() {
    this.commentData.comment
      ? this.commentForm.get('comment')?.setValue(this.commentData.comment)
      : this.commentForm.get('comment')?.setValue('');
  }

  /**
   * @description - Function to submit the general planning form
   */
  async submitCommentForm() {
    if (this.commentForm.valid) {
      this.disableSubmit = true;
      const data: any = {   
        projectId: this.projectId,
        comment: this.commentForm.controls.comment.value  
      };  
      if(this.isEdit){
        data.reviewId = this.commentData.id;
      }
      try {
        const response: any = await this.projectScopingService.manageComment(
          data,
          this.action
        );
        if (response.statusCode === 200) {
          this.commentForm.reset();
          this.isFormSubmitted = true;
          this.disableSubmit = false;
          this.modalRef.hide();
          this.projectScopingService.commentEvent.next(true);
          this.commentEvent.emit(true);
          this.alertService.show(response.message, 'success');
        } else {
          this.disableSubmit = false;
          this.modalRef.hide();
          this.alertService.show(response.message, 'info');
        }
      } catch (e: any) {
        this.disableSubmit = false;
        this.modalRef.hide();
        this.alertService.show(e.error.message, 'error');
      }
    } else {
      this.isFormSubmitted = false;
    }
  }
}
