import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {
  @Input() dataSource: any = [];
  @Input() avgTest: any = [];
  constructor() { }

  ngOnInit(): void {
    this.avgTest = Math.trunc(this.avgTest);
  }

  customizeTooltip = (arg: any) => ({
    text: `${arg.argumentText} - ${arg.value}`,
  });

}
