<ng-template #alert>
    <div class="modal-body sucess-modal-body">
        <button type="button" data-dismiss="modal" aria-label="Close" class="close closebtn ab-btn"
            (click)="modalRef.hide()">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.4372 10.8006L17.3872 5.85059L18.8012 7.26459L13.8512 12.2146L18.8012 17.1646L17.3872 18.5786L12.4372 13.6286L7.48724 18.5786L6.07324 17.1646L11.0232 12.2146L6.07324 7.26459L7.48724 5.85059L12.4372 10.8006Z"
                    fill="black" />
            </svg>
        </button>
        <div class="s-modal-content mr-t20">
            <svg *ngIf="title === 'info'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px"><path fill="#2196f3" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"/><path fill="#fff" d="M22 22h4v11h-4V22zM26.5 16.5c0 1.379-1.121 2.5-2.5 2.5s-2.5-1.121-2.5-2.5S22.621 14 24 14 26.5 15.121 26.5 16.5z"/></svg>
            <svg *ngIf="title === 'success'" width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M26.8368 53.5732C12.1088 53.5732 0.170166 41.6345 0.170166 26.9065C0.170166 12.1785 12.1088 0.239838 26.8368 0.239838C41.5648 0.239838 53.5035 12.1785 53.5035 26.9065C53.5035 41.6345 41.5648 53.5732 26.8368 53.5732ZM24.1782 37.5732L43.0315 18.7172L39.2608 14.9465L24.1782 30.0318L16.6342 22.4878L12.8635 26.2585L24.1782 37.5732Z"
                    fill="#27AE60" />
            </svg>
            <svg *ngIf="title === 'error'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px"><path fill="#f44336" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"/><path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"/><path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"/></svg>
            <h4>{{title | titlecase}}!</h4>
            <p>
                {{alertMessage + '.'}}
            </p>
        </div>
    </div>
</ng-template>