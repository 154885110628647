import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SampleControlService {
  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  /**
   * @description - Http call to get the order list for project management screen
   * @returns
   */
  getOrderList(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/sampleControl/getSampleControlList?searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the product details
   * @returns
   */
  getScannedProduct(barCode: any, orderId: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/sampleControl/getDetailsForScannedProduct?barCode=${barCode}&orderId=${orderId}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to deallocate the product
   * @returns
   */
  deallocateProduct(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/sampleControl/deallocateProduct`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get recieved product list
   * @returns
   */
  getRecievedList(data: any) {
    let url = '';
    if (data.canisterSubTypeId) {
      url = `?productId=${data.productId}&orderId=${data.orderId}&canisterSubTypeId=${data.canisterSubTypeId}`;
    } else if (data.manifoldSubTypeId) {
      url = `?productId=${data.productId}&orderId=${data.orderId}&manifoldsSubTypeId=${data.manifoldSubTypeId}`;
    } else {
      url = `?productId=${data.productId}&orderId=${data.orderId}`;
    }
    url += `&isUnused=${data.isUnused}`;
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/sampleControl/listAllReceivedProducts${url}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get list of missing products
   * @returns
   */
  getMissingList(orderId: number) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/sampleControl/listAllMissingProducts?orderId=${orderId}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get order details for sample control
   * @returns
   */
  getOrderDetails(orderId: number) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/sampleControl/getReturnOrderDetails?orderId=${orderId}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get list of products inside order
   * @returns
   */
  viewOrderInventory(orderId: number) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/sampleControl/viewOrderInventory?orderId=${orderId}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to mark item as lost
   * @returns
   */
  markLostItem(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/sampleControl/markLostItem`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to send order for testing department with partially open and closed status
   * @returns
   */
  submitForTest(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/sampleControl/submitReturnOrder`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get return count
   * @returns
   */
  getReturnCount(orderId: number) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/sampleControl/returnCount?orderId=${orderId}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to delete the allocated product
   * @param data - deleted data
   */
  deleteProduct(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(
        `${environment.apiUrl}/sampleControl/deleteProductFromReceivedList`,
        data,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the order pdf
   * @returns
   */
  getOrderPdf(orderId: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/sampleControl/getOrderPdf?orderId=${orderId}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }
}
