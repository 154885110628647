<ng-template #checklist>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
      Checklist for quote creation
    </h5>
    <button
      (click)="modalRef.hide()"
      type="button"
      class="close m-close-btn"
      data-dismiss="modal"
      aria-label="Close"
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.11107 5.36626L12.0611 0.41626L13.4751 1.83026L8.52507 6.78026L13.4751 11.7303L12.0611 13.1443L7.11107 8.19426L2.16107 13.1443L0.74707 11.7303L5.69707 6.78026L0.74707 1.83026L2.16107 0.41626L7.11107 5.36626Z"
          fill="white"
        />
      </svg>
    </button>
  </div>
  <div class="modal-body plan-modal">
    <form [formGroup]="checklistForm">
      <div class="cell-d-info">
        <div id="verify">
          <div class="row project-details-info" *ngIf="projectDetails">
            <div class="col-sm-6 d-flex align-items-center mb-2">
              <h5>Project ID :</h5>
              <span class="ml-2">{{ projectDetails.project_id }}</span>
            </div>

            <div class="col-sm-6 d-flex mb-2" *ngIf="userData">
              <h5>Quote Prepared by :</h5>
              <span class="ml-2">{{
                userData.first_name + " " + userData.last_name
              }}</span>
            </div>

            <div class="col-sm-6 d-flex">
              <h5 class="mr-b10">Client Name :</h5>
              <span class="ml-2">{{ projectDetails.client_name }}</span>
            </div>

            <div class="col-sm-6 d-flex">
              <h5>Date :</h5>
              <span class="ml-2">{{today | date: "MM-dd-YYYY"}}</span>
            </div>
          </div>

          <div>
            <div class="cus-b-head-table table-responsive mr-t20">
              <table class="quote-checklist">
                <thead>
                  <tr>
                    <th width="20">No</th>
                    <th>Checklist for Quotes</th>
                    <th width="50px" class="thead-checkbox">
                      <div class="cus-check-btn t-chack">
                        <label for="test">
                          <!-- Check All -->
                          <input
                            type="checkbox"
                            id="test"
                            (change)="checkAll($event)"
                          />
                          <span></span>
                        </label>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let list of verifyList; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ list.particulars }}</td>
                    <td>
                      <div class="cus-check-btn t-chack">
                        <label for="item-{{ list.id }}">
                          <input
                            (change)="onCheckboxSelect($event, list.id)"
                            formControlName="item-{{ list.id }}"
                            type="checkbox"
                            id="item-{{ list.id }}"
                          />
                          <span></span>
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div>
            <div class="cus-b-head-table table-responsive mr-t20">
              <table class="rfp-checklist">
                <thead>
                  <tr>
                    <th width="20">No</th>
                    <th>RFP Checklist</th>
                    <th width="50px" class="thead-checkbox">
                      <div class="cus-check-btn t-chack">
                        <label for="rfp">
                          <input
                            type="checkbox"
                            id="rfp"
                            (change)="checkAllRfp($event)"
                          />
                          <span></span>
                        </label>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let list of rfpList; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ list.particulars }}</td>

                    <div class="cus-check-btn t-chack">
                      <label for="item-{{ list.id }}">
                        <input
                          (change)="onCheckboxSelect($event, list.id)"
                          formControlName="item-{{ list.id }}"
                          type="checkbox"
                          id="item-{{ list.id }}"
                        />
                        <span></span>
                      </label>
                    </div>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <mat-error class="mt-2" *ngIf="showListValidation">
            Please complete all the items in checklist
          </mat-error>

          <br />
          <h5 class="input-title mr-b10">Comments</h5>

          <textarea
            [maxlength]="300"
            formControlName="comments"
            rows="4"
            class="form-control"
            placeholder="Enter comments here"
          >
          </textarea>
        </div>

        <div class="dilevrynote order-comments-note">
          <p class="text-muted">Note : Add comments up to 300 characters</p>
        </div>
        <mat-error
          *ngIf="
            show &&
            (checklistForm.controls.comments.touched || !isFormSubmitted) &&
            checklistForm.controls.comments.errors?.required
          "
        >
          Please add comments
        </mat-error>
        <div class="bottom-btn text-right mr-t20 mr-b20">
          <button
            type="button"
            class="blue-line-btn blue-line-ltext h32 min-w160 mr-r10"
            (click)="getPDF()"
          >
            Download
          </button>
          <button
            type="submit"
            (click)="verifyChcklist()"
            class="blue-line-btn blue-line-ltext h32 min-w160"
            [disabled]="!checklistForm.valid"
          >
            Verified
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
