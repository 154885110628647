import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './alert/alert.component';
import { RouterModule } from '@angular/router';
import { ConfirmAlertComponent } from './confirm-alert/confirm-alert.component';
import { OrderConfirmComponent } from './order-confirm/order-confirm.component';
import { CellDetailsComponent } from './cell-details/cell-details.component';
import { LoaderComponent } from './loader/loader.component';
import { DoughchartComponent } from './doughchart/doughchart.component';
import { DxBulletModule, DxButtonModule, DxChartModule, DxDataGridModule, DxPieChartModule, DxSelectBoxModule } from 'devextreme-angular';
import { SharedAddPlanComponent } from './shared-add-plan/shared-add-plan.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { LineChartComponent } from './line-chart/line-chart.component';
import { SharedEmployeePlanningComponent } from './shared-employee-planning/shared-employee-planning.component';
import { ReviewerListComponent } from './reviewer-list/reviewer-list.component';
import { AddCommentComponent } from './add-comment/add-comment.component';
import { SharedQuoteVerifyChecklistComponent } from './shared-quote-verify-checklist/shared-quote-verify-checklist.component';

@NgModule({
  declarations: [
    AlertComponent,
    ConfirmAlertComponent,
    OrderConfirmComponent,
    CellDetailsComponent,
    LoaderComponent,
    DoughchartComponent,
    SharedAddPlanComponent,
    LineChartComponent,
    SharedEmployeePlanningComponent,
    ReviewerListComponent,
    AddCommentComponent,
    SharedQuoteVerifyChecklistComponent
  ],
  imports: [    
    CommonModule,
    RouterModule,
    DxChartModule,
    DxPieChartModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DxSelectBoxModule,
    DxDataGridModule,
    DxBulletModule,
    DxButtonModule,
  ],
  exports: [
    AlertComponent,
    ConfirmAlertComponent,
    OrderConfirmComponent,
    CellDetailsComponent,
    LoaderComponent,
    DoughchartComponent,
    SharedAddPlanComponent,
    LineChartComponent,
    SharedEmployeePlanningComponent,
    ReviewerListComponent,
    AddCommentComponent,
    SharedQuoteVerifyChecklistComponent
  ],
})
export class SharedModule {}
