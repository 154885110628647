<dx-pie-chart id="chart" type="doughnut" [title]="title" [dataSource]="dataSource" 
palette="servicePortalPalette">
  <dxi-series argumentField="name">
    <dxo-label [visible]="false">
      <dxo-connector [visible]="false"></dxo-connector>
    </dxo-label>
  </dxi-series>
  <dxo-tooltip
    [enabled]="true"
    [customizeTooltip]="customizeTooltip"
  >
  </dxo-tooltip>

  <dxo-legend
    position="outside"
    horizontalAlignment="center"
    verticalAlignment="middle"
  >
  </dxo-legend>
</dx-pie-chart>
