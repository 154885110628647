import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as $ from 'jquery';
import { Observable } from 'rxjs';
import { menuArr } from 'src/app/config/menu';
import { AdminService } from 'src/app/services/admin/admin.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { UserService } from 'src/app/services/user/user.service';
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  sideMenuArr: any = [];
  finalSideMenuArr: any = [];
  activeLink: string = '';
  activeMenuName: string = '';
  subActiveLink: string = '';
  userPermissionArr: any = [];
  userData: any;
  isLoggedIn$!: Observable<boolean>;
  staticMenuArr: any = menuArr;
  @ViewChild('sidemenu') sidemenu!:ElementRef ;
  constructor(
    private alertService: AlertService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private adminService: AdminService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const activeLink = event.url.split('/');
        this.activeLink = `/${activeLink[1]}`;
        this.subActiveLink = event.url;
        // console.log(this.activeLink, this.subActiveLink);
      }
    });
    this.getModuleList();
    this.getUserPermissions();
    $('.new-nav-list li').on('click',function () {
      console.log('clicked');
      $(this)
        .parent()
        .addClass('active-link')
        .siblings()
        .removeClass('active-link');
    });
    $('.menu-btn').click(function () {
      $('.sidebar-wrap').toggleClass('hide-sidebar');
    });
  }

  // ngAfterViewInit(){
  //   $(this.sidemenu.nativeElement).on('click',function () {
  //     console.log('clicked', $(this).children());
  //     $(this)
  //       .parent()
  //       .addClass('active-link')
  //       .siblings()
  //       .removeClass('active-link');
  //   });
  // }

  getMainActiveClass(sublink: string){
    let subclass = '';
    if(this.activeLink.includes(sublink)){
      subclass = 'active-link'
    }
    return subclass;
  }

  getSubActiveClass(sublink: string){
    let subclass = '';
    if(this.subActiveLink.includes(sublink)){
      subclass = 'sub-active'
    }
    return subclass;
  }

  /**
   * @description - Function to logout the user
   */
  logoutUser() {
    const subscription = this.alertService
      .showConfirmAlert('Are you sure you want to logout?')
      .subscribe((data) => {
        if (data === true) {
          this.localStorageService.removeItem('isUserLogin');
          this.localStorageService.removeItem('userDetails');
          this.localStorageService.removeItem('token');
          subscription.unsubscribe();
          this.router.navigate(['/auth/login']);
        }
      });
  }

  /**
   * @description - Function to set the active link to add active class
   * @param currentLink - currently active link
   */
  setActiveLink(currentLink: string) {
    if(!currentLink){
      this.activeLink = this.subActiveLink;
    }else{
      this.activeLink = currentLink;
    }
    // console.log('activeLink === ',this.activeLink, this.subActiveLink);
  }

  /**
   * @description - Function to get the user permissions from local storage
   */
  getUserPermissions() {
    const json: any = this.localStorageService.getData('userDetails');
    this.userData = JSON.parse(json);
    if (this.userData && this.userData.user_permission_array) {
      this.userPermissionArr = this.userData.user_permission_array;
    }
  }

  /**
   * @description - Function to check if main menu url is in permission array
   * @param id - menu main id
   * @returns
   */
   checkIfValidALMenu(id: string) {
    if (!id) {
      return true;
    }
    const found = this.userPermissionArr.some((el: any) => el.module_id === id);
    return found ? true : false;
  }

  /**
   * @description - Function to check if main menu url is in permission array
   * @param id - menu main id
   * @returns
   */
  checkIfValidMenu(id: string) {
    if (!id) {
      return true;
    }
    const found = this.userPermissionArr.some((el: any) => el.module_id === id
    || el.sub_module_id === id);
    return found ? true : false;
  }

  /**
   * @description - Function to check if sub menu url is in permission array
   * @param id - sub main id
   * @returns
   */
  checkIfValidSubMenu(subid: string) {
    if (!subid) {
      return true;
    }
    const found = this.userPermissionArr.some(
      (el: any) => el.sub_module_id === subid
    );
    return found ? true : false;
  }

  isSales(){
    let isSales = false;
    const isProjectAccess = this.userPermissionArr.some((elm:any) => {
      if (elm.module_name === 'Project Scoping & Management') {
        return true;
      }    
      return false;
    });
    if(this.userPermissionArr.length === 1 && isProjectAccess){
        isSales = true;
    }
    return isSales;
  }

  /**
   * @description - Function to get the module list
   */
  async getModuleList() {
    try {
      const response: any = await this.adminService.getModuleList();
      if (response.statusCode === 200) {
        this.sideMenuArr = response.result;
        const obj = {
          NAME: 'Store Management',
          icon: 'assets/images/sidemenu/order-management.svg',
          id: 0,
          // link:'/dashboard',
          sub_module: [],
        };
        if(this.userPermissionArr 
          && this.userPermissionArr.length > 0
          && !this.isSales() ){
          this.finalSideMenuArr.push(obj);
        }

        this.createSidemenuArr();
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * @description - Function to create sidemenu array
   */
  createSidemenuArr() {
    this.sideMenuArr.map((menu: any, i: number) => {
      menu.link = `/${menu.NAME.replace(/\s+/g, '-').toLowerCase()}`;
      menu.icon = `/assets/images/sidemenu/${menu.NAME.replace(
        /\s+/g,
        '-'
      ).toLowerCase()}.svg`;
      if (
        menu.NAME !== 'Operations' &&
        menu.NAME !== 'Admin' &&
        menu.NAME !== 'Project Scoping & Management'
      ) {
        this.pushMenu(menu);
      } else {
        if (menu.NAME === 'Operations') {
          this.finalSideMenuArr.push(menu);
          const temp = menu.sub_module;
          menu.sub_module = [];
          menu.sub_module = this.staticMenuArr[0].operation_menu;
          menu.sub_module.map((subMenu: any, i: number) => {
            if (subMenu.sub_module_name === 'Time Card') {
              temp.map((tempMenu: any) => {
                tempMenu.link = `/operations/${tempMenu.sub_module_name
                  .replace(/\s+/g, '-')
                  .toLowerCase()}`;
                tempMenu.icon = `/assets/images/sidemenu/${tempMenu.sub_module_name
                  .replace(/\s+/g, '-')
                  .toLowerCase()}.svg`;
                if(this.userData.role_id !== 1){
                  if(this.checkIfValidMenu(tempMenu.sub_module_id)){
                    menu.sub_module.splice(i, 0, tempMenu);
                  }
                }else{
                  menu.sub_module.splice(i, 0, tempMenu);
                }                
              });
            }
          });
          // console.log(menu)
        }else if(menu.NAME === 'Project Scoping & Management'){
          if(this.userData.role_id === 1){
            this.finalSideMenuArr.push(menu);
            menu.sub_module = this.staticMenuArr[0].project_menu;
          }else{
            if(this.checkIfValidMenu(menu.id)){
              this.finalSideMenuArr.push(menu);
              menu.sub_module = this.staticMenuArr[0].project_menu;
            }
          }
        }
      }
    });

    // console.log(this.finalSideMenuArr);
  }

  pushMenu(sub_module: any) {
    this.finalSideMenuArr.map((menu: any) => {
      if (menu.NAME === 'Store Management') {
        const obj = {
          sub_module_id: sub_module.id,
          sub_module_name: sub_module.NAME,
          link: `/${sub_module.NAME.replace(/\s+/g, '-').toLowerCase()}`,
          icon: `/assets/images/sidemenu/${sub_module.NAME.replace(
            /\s+/g,
            '-'
          ).toLowerCase()}.svg`,
          second_sub_module: sub_module.sub_module,
        };
        if (sub_module.sub_module.length > 0) {
          sub_module.sub_module.map((submenu: any) => {
            let mainMenuLink = `/${sub_module.NAME.replace(
              /\s+/g,
              '-'
            ).toLowerCase()}`;
            submenu.link = `${mainMenuLink}/${submenu.sub_module_name
              .replace(/\s+/g, '-')
              .toLowerCase()}`;
            submenu.icon = `/assets/images/sidemenu/submenu.svg`;
          });
        }
        if(this.userData.role_id !== 1){
          if(this.checkIfValidALMenu(sub_module.id)){
            menu.sub_module.push(obj);
          }
        }else{
          menu.sub_module.push(obj);
        }        
      }
    });
  }

  hideOrShowSubmenu(link:string){
    let show = false;
    if(this.subActiveLink.includes(link)){
      show = true;
    }
    return show;
  }

}
