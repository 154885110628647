import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertService } from 'src/app/services/alert/alert.service';
import { OperationsService } from 'src/app/services/operations/operations.service';

@Component({
  selector: 'app-shared-add-plan',
  templateUrl: './shared-add-plan.component.html',
  styleUrls: ['./shared-add-plan.component.scss'],
})
export class SharedAddPlanComponent implements OnInit {
  modalRef!: BsModalRef;
  modalSub!: any;
  @Output() close = new EventEmitter<boolean>();
  @Output() planEvent = new EventEmitter<boolean>();
  today = new Date();
  deptArr: any;
  empArr: any;
  planForm!: FormGroup;
  isFormSubmitted = true;
  @ViewChild('addPlan', { static: true }) templateEl!: TemplateRef<any>;
  @Input() planData: any;
  @Input() isEdit: boolean = false;
  @Input() departmentId: any;
  @Input() employeeId: any;
  action: string = 'addPlan';
  @Input() isEmployee: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private operationService: OperationsService,
    private alertService: AlertService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.initModal();
    this.getMasterData();
    this.initPlanform();
  }

  /**
   * @description - Function to initialize the modal reference variable
   */
  initModal() {
    this.modalRef = this.modalService.show(this.templateEl, {
      class: 'modal-dialog-centered dashboard-modal',
    });
    this.modalSub = this.modalRef.onHidden?.subscribe((val: any) => {
      this.close.emit(true);
    });
  }

  /**
   * @description - Function to initialize the plan form with form control
   */
  initPlanform() {
    this.planForm = this.formBuilder.group({
      department_id: ['', [Validators.required]],
      employee_id: ['', [Validators.required]],
      date: ['', [Validators.required]],
      hours: ['', [Validators.required, Validators.min(1), Validators.max(24)]],
      minutes: ['',[Validators.min(1), Validators.max(99)]],
      description: ['', [Validators.required]],
      priority: ['', [Validators.required]],
      status: [''],
    });

    if(this.isEmployee){
      this.planForm.get('department_id')?.setValue(this.departmentId);
      this.planForm.get('employee_id')?.setValue(this.employeeId);
    }

    if (this.isEdit) {
      this.action = 'editPlan';
      this.patchFormValues();
    }
  }

  patchFormValues() {
    console.log(this.planData);
    this.planData.department_id
      ? this.planForm
          .get('department_id')
          ?.setValue(this.planData.department_id)
      : this.planForm.get('department_id')?.setValue('');

    this.planData.employee_id
      ? this.planForm.get('employee_id')?.setValue(this.planData.employee_id)
      : this.planForm.get('employee_id')?.setValue('');

    this.planData.plan_date
      ? this.planForm.get('date')?.setValue(this.planData.plan_date)
      : this.planForm.get('date')?.setValue('');

    this.planData.description
      ? this.planForm.get('description')?.setValue(this.planData.description)
      : this.planForm.get('description')?.setValue('');

    if (this.planData.priority === 'Low') {
      this.planForm.get('priority')?.setValue(1);
    } else if (this.planData.priority === 'Medium') {
      this.planForm.get('priority')?.setValue(2);
    } else {
      this.planForm.get('priority')?.setValue(3);
    }

    if (this.planData.status === 'Pending') {
      this.planForm.get('status')?.setValue(false);
    } else {
      this.planForm.get('status')?.setValue(true);
    }

    if (this.planData.time_required) {
      const hours = Math.floor(this.planData.time_required / 60);
      this.planForm.get('hours')?.setValue(hours);
      const minutes = this.planData.time_required % 60;
      this.planForm.get('minutes')?.setValue(minutes);
    }
  }

  /**
   * @description - Function to submit the general planning form
   */
  async submitPlanForm() {
    console.log(this.planForm)
    if (this.planForm.valid) {
      const controls = this.planForm.controls;
      const minutes = Math.floor(controls.hours.value * 60);
      const totminutes = minutes + controls.minutes.value;
      const data: any = {
        planDate: this.operationService.formatDate(
          this.planForm.controls.date.value
        ),
        description: this.planForm.controls.description.value,
        timeRequired: totminutes,
        priority: this.planForm.controls.priority.value,
        is_other_plan: 0,
        employee_id: this.planForm.controls.employee_id.value,
        department_id: this.planForm.controls.department_id.value,
      };
      if (this.employeeId) {
        data.plan_owner_id = this.employeeId;
      }
      if (this.departmentId) {
        data.department_id = this.departmentId;
      }
      if (this.isEdit) {
        data.planId = this.planData.id;
      }
      if (this.isEdit) {
        data.descriptions = this.planForm.controls.description.value;
      }
      if (this.isEdit) {
        data.status = this.planForm.controls.status.value ? 2 : 1;
      }
      console.log('plan data ==== ',data);
      try {
        const response: any = await this.operationService.managePlan(
          data,
          this.action
        );
        console.log(response);
        if (response.statusCode === 200) {
          this.planForm.reset();
          this.modalRef.hide();
          this.planEvent.emit(true);
          this.alertService.show(response.message, 'success');
        } else {
          this.modalRef.hide();
          this.alertService.show(response.message, 'info');
        }
      } catch (e: any) {
        console.log(e);
        this.modalRef.hide();
        this.alertService.show(e.error.message, 'error');
      }
    } else {
      this.isFormSubmitted = false;
    }
  }

  async deletePlan() {
    const msg = `Are you sure you want to delete this plan?`;
    const header = 'Delete Plan';
    this.modalRef.hide();
    const res = await this.alertService.confirm(msg, header);
    if (res) {
      try {
        const data: any = {
          planId: this.planData.id,
        };
        const response: any = await this.operationService.managePlan(
          data,
          'deletePlan'
        );
        console.log(response);
        if (response.statusCode === 200) {
          this.planEvent.emit(true);
          this.alertService.show(response.message, 'success');
        } else {
          this.modalRef.hide();
          this.alertService.show(response.message, 'info');
        }
      } catch (e: any) {
        console.log(e);
        this.modalRef.hide();
        this.alertService.show(e.error.message, 'error');
      }
    }
  }

  /**
   * @description - Function to set the status value
   * @param event - event data
   */
  onStatusChange(event: any) {
    if (event.target.checked) {
      this.planForm.get('status')?.setValue(true);
      this.planData.status = 'Completed';
    } else {
      this.planForm.get('status')?.setValue(false);
      this.planData.status = 'Pending';
    }
  }

  /**
   * @description - Function to get the user by department
   */
  async getMasterData() {
    try {
      const response: any = await this.operationService.getMasterData();
      if (response.statusCode === 200) {
        this.deptArr = response.result.department;
        this.getUsersByDept(this.planData.department_id);
      }
    } catch (e: any) {
      // this.loaderService.hide();
    }
  }

  /**
   * @description - Function to get the user by department
   */
  async getUsersByDept(deptId: any) {
    try {
      const response: any = await this.operationService.getUsersByDept(deptId);
      if (response.statusCode === 200) {
        this.empArr = response.result.length > 0 ? response.result : [];
      }
    } catch (e: any) {
      console.log(e);
    }
  }
}
