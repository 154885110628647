<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li 
        *ngFor="let menu of urlArr;let i = index; let last = last" 
        class="breadcrumb-item active" 
        [ngClass]="{ 'active' : last }"
        aria-current="page">
            <a role="button" *ngIf="!last" [routerLink]="menu.link">{{menu.menuName | titlecase}}</a>
            <span *ngIf="last">{{menu.menuName | titlecase}}</span>
        </li>
    </ol>
</nav>
