import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { OperationsService } from 'src/app/services/operations/operations.service';

@Component({
  selector: 'app-shared-employee-planning',
  templateUrl: './shared-employee-planning.component.html',
  styleUrls: ['./shared-employee-planning.component.scss'],
})
export class SharedEmployeePlanningComponent implements OnInit {
  deptArr: any;
  departmentId: any;
  employeeId: any;
  planArr: any;
  showAddPlan: boolean = false;
  isEdit: boolean = false;
  planData: any;
  empArr: any;
  skipRecord: number = 0;
  pageSize: number = 5;
  total_records: number = 0;
  pages: any[] = [];
  activePage: number = 1;
  constructor(
    private loaderService: LoaderService,
    public operationService: OperationsService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.getMasterData();
  }

  getTotalHrs(day: string) {
    let hours = 0;
    if (this.planArr !== undefined && this.planArr[day].length > 0) {
      let sum: number = this.planArr[day]
        .map((a: any) => a.time_required)
        .reduce((a: any, b: any) => {
          return a + b;
        });
      hours = Math.floor(sum / 60);
    }
    return hours;
  }

  getPendingCount(day: string) {
    let pendingArr = [];
    if (this.planArr && day === 'today') {
      pendingArr = this.planArr.today.filter((plan: any) => {
        return plan.status === 'Pending';
      });
    } else if (this.planArr && day === 'tomorrow') {
      pendingArr = this.planArr.tomorrow.filter((plan: any) => {
        return plan.status === 'Pending';
      });
    } else if (this.planArr && day === 'day_after_tomorrow') {
      pendingArr = this.planArr.day_after_tomorrow.filter((plan: any) => {
        return plan.status === 'Pending';
      });
    } else {
      pendingArr = [];
    }
    return pendingArr ? pendingArr.length : 0;
  }

  getPlanModifiedEvent() {
    this.operationService.planModified.subscribe((data: any) => {
      this.getEmployeePlanning(true);
    });
  }

  /**
   * @description - Function to get the user by department
   */
  async getUsersByDept(deptId: any) {
    try {
      this.departmentId = deptId;
      const response: any = await this.operationService.getUsersByDept(deptId);
      if (response.statusCode === 200) {
        this.loaderService.hide();
        this.empArr = response.result.length > 0 ? response.result : [];
      } else {
        this.loaderService.hide();
        this.alertService.show(response.message, 'info');
      }
    } catch (e: any) {
      this.loaderService.hide();
      console.log(e);
      this.alertService.show(e.error.message, 'error');
    }
  }

  onUserSelect(employeeId: any) {
    this.employeeId = employeeId;
    this.getEmployeePlanning(true);
  }

  /**
   * @description - Function to get the user by department
   */
  async getMasterData() {
    try {
      const response: any = await this.operationService.getMasterData();
      if (response.statusCode === 200) {
        this.deptArr = response.result.department;
      }
    } catch (e: any) {
      // this.loaderService.hide();
    }
  }

  /**
   * @description - Function to get the order list
   */
  async getEmployeePlanning(isFirst?: boolean) {
    this.loaderService.show();
    try {
      const data: any = {
        departmentId: this.departmentId ? this.departmentId : '',
        planOwnerId: this.employeeId ? this.employeeId : '',
        currentDate: this.operationService.formatDate(new Date().toUTCString()),
        // skipRecord: this.skipRecord,
        // pageSize: this.pageSize,
      };
      const response: any = await this.operationService.getEmployeePlanning(
        data
      );
      if (response.statusCode === 200) {
        this.loaderService.hide();
        this.planArr = response.result;
        this.total_records = response.result.total_records;
        // if (isFirst) {
        //   const pageCount = this.getPageCount();
        //   this.pages = this.getArrayOfPage(pageCount);
        // }
      } else {
        this.loaderService.hide();
      }
    } catch (e) {
      this.planArr = [];
      console.log(e);
      this.loaderService.hide();
    }
  }

  /**
   * @description - Function to open add plan modal
   */
  openAddPlan(isEdit: boolean, planData?: any) {
    this.showAddPlan = true;
    this.planData = [];
    if (isEdit) {
      this.isEdit = true;
      planData.department_id = this.departmentId;
      planData.employee_id = this.employeeId;
      this.planData = planData;
    }
  }

  /**
   * @description - Function to delete the plan
   */
  async deletePlan(plan:any) {
    const msg = `Are you sure you want to delete this plan?`;
    const header = 'Delete Plan';
    const res = await this.alertService.confirm(msg, header);
    if (res) {
      try {
        const data: any = {
          planId: plan.id,
        };
        const response: any = await this.operationService.managePlan(
          data,
          'deletePlan'
        );
        console.log(response);
        if (response.statusCode === 200) {
          this.alertService.show(response.message, 'success');
          this.getEmployeePlanning();
        } else {
          this.alertService.show(response.message, 'info');
        }
      } catch (e: any) {
        console.log(e);
        this.alertService.show(e.error.message, 'error');
      }
    }
  }
}
