<ng-template #addPlan>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">
      {{ isEdit ? "Edit" : "Add" }} Plan
    </h5>
    <button
      (click)="modalRef.hide()"
      type="button"
      class="close m-close-btn"
      data-dismiss="modal"
      aria-label="Close"
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.11107 5.36626L12.0611 0.41626L13.4751 1.83026L8.52507 6.78026L13.4751 11.7303L12.0611 13.1443L7.11107 8.19426L2.16107 13.1443L0.74707 11.7303L5.69707 6.78026L0.74707 1.83026L2.16107 0.41626L7.11107 5.36626Z"
          fill="white"
        />
      </svg>
    </button>
  </div>
  <form [formGroup]="planForm">
    <div class="modal-body plan-modal">
      <div class="plan-form-wrap mx-auto">
        <div class="row align-items-baseline">
          <div class="col-4" *ngIf="!isEmployee">
            <div class="static-info box-input">
              <h5>Department</h5>
            </div>
          </div>
          <div class="col-8" *ngIf="!isEmployee">
            <div class="box-input mr-b20">
              <div class="form-group justify-content-between mr-b10">
                <mat-form-field appearance="standard" class="w-100">
                  <mat-select
                    formControlName="department_id"
                    class="form-control"
                    [disabled]="true"
                  >
                    <mat-option *ngFor="let dept of deptArr" [value]="dept.id">
                      {{ dept.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="col-4" *ngIf="!isEmployee">
            <div class="static-info box-input">
              <h5>Employee</h5>
            </div>
          </div>
          <div class="col-8" *ngIf="!isEmployee">
            <div class="box-input mr-b20">
              <div class="form-group justify-content-between mr-b10">
                <mat-form-field appearance="standard" class="w-100">
                  <mat-select
                    formControlName="employee_id"
                    class="form-control"
                    [disabled]="true"
                  >
                    <mat-option *ngFor="let emp of empArr" [value]="emp.id">
                      {{ emp.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="static-info box-input">
              <h5>Date <span class="required-asterisk">*</span></h5>
            </div>
          </div>
          <div class="col-8 mr-b20">
            <div class="static-info box-input dateInput">
              <div class="form-group justify-content-between mr-b10">
                <mat-form-field class="form-control" appearance="standard">
                  <input
                    [disabled]="true"
                    matInput
                    [min]="today"
                    [matDatepicker]="picker1"
                    placeholder="Plan Date"
                    formControlName="date"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker1">
                  </mat-datepicker-toggle>
                  <mat-datepicker [disabled]="false" #picker1> </mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <mat-error
              *ngIf="
                (planForm.controls.date.touched || !isFormSubmitted) &&
                planForm.controls.date.errors?.required
              "
            >
              Please select date
            </mat-error>
          </div>

          <div class="col-sm-4">
            <div class="static-info box-input">
              <h5 class="mr-t10">
                Time Required(hh:mm) <span class="required-asterisk">*</span>
              </h5>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="static-info box-input mr-b20 dateInput time-required">
              <div class="form-group mr-b10">
                <mat-form-field class="form-control w-25" appearance="standard">
                  <input
                    matInput
                    type="number"
                    [min]="1"
                    [max]="24"
                    formControlName="hours"
                    placeholder="hh"
                  />
                </mat-form-field>
                <span> : </span>

                <mat-form-field class="form-control w-25" appearance="standard">
                  <input
                    matInput
                    type="number"
                    [min]="1"
                    [max]="99"
                    formControlName="minutes"
                    placeholder="mm"
                  />
                </mat-form-field>
              </div>
              <mat-error
                *ngIf="
                  (planForm.controls.hours.touched || !isFormSubmitted) &&
                  planForm.controls.hours.errors?.required
                "
              >
                Please enter time required
              </mat-error>

              <mat-error
                *ngIf="
                  (planForm.controls.hours.touched || !isFormSubmitted) &&
                  planForm.controls.hours.errors?.min
                "
              >
                Hours must be greater than zero
              </mat-error>

              <mat-error
                *ngIf="
                  (planForm.controls.minutes.touched || !isFormSubmitted) &&
                  planForm.controls.minutes.errors?.min
                "
              >
                Minutes must be greater than zero
              </mat-error>

              <mat-error
                *ngIf="
                  (planForm.controls.hours.touched || !isFormSubmitted) &&
                  planForm.controls.hours.errors?.max
                "
              >
                Hours value is out of range
              </mat-error>

              <mat-error
                *ngIf="
                  (planForm.controls.minutes.touched || !isFormSubmitted) &&
                  planForm.controls.minutes.errors?.max
                "
              >
                Minutes value is out of range
              </mat-error>
            </div>
          </div>

          <div class="col-4">
            <div class="static-info box-input">
              <h5>Description<span class="required-asterisk">*</span></h5>
            </div>
          </div>
          <div class="col-8 mr-b20">
            <div class="static-info box-input">
              <div class="form-group justify-content-between mr-b10">
                <mat-form-field appearance="standard" class="w-100">
                  <textarea
                    matInput
                    formControlName="description"
                    class="form-control"
                    rows="5"
                    placeholder="Message"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
            <mat-error
              *ngIf="
                (planForm.controls.description.touched || !isFormSubmitted) &&
                planForm.controls.description.errors?.required
              "
            >
              Please provide description
            </mat-error>
          </div>

          <div class="col-sm-4">
            <div class="static-info box-input">
              <h5 class="mr-t10">
                Priority<span class="required-asterisk">*</span>
              </h5>
            </div>
          </div>
          <div class="col-sm-8">
            <div class="static-info box-input mr-b20">
              <div class="form-group mr-b10">
                <mat-form-field appearance="standard" class="w-100">
                  <mat-select
                    class="form-control"
                    formControlName="priority"
                    placeholder="Select"
                  >
                    <mat-option [value]="1">Low</mat-option>
                    <mat-option [value]="2">Medium</mat-option>
                    <mat-option [value]="3">High</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <mat-error
                *ngIf="
                  (planForm.controls.priority.touched || !isFormSubmitted) &&
                  planForm.controls.priority.errors?.required
                "
              >
                Please select priority
              </mat-error>
            </div>
          </div>

          <div class="col-4" *ngIf="isEdit">
            <div class="static-info box-input">
              <h5>Status</h5>
            </div>
          </div>
          <div class="col-8" *ngIf="isEdit">
            <div class="red-switch-btn">
              <label for="s1" class="d-flex align-items-center">
                <input
                  type="checkbox"
                  formControlName="status"
                  name=""
                  (change)="onStatusChange($event)"
                  id="s1"
                />
                <span></span>
                <em>{{ planData.status }}</em>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="p-mbtn mr-t40 text-right">
        <button
          type="button"
          *ngIf="!isEdit"
          (click)="modalRef.hide()"
          class="blue-line-btn blue-line-ltext h32 pd-l10 pd-r10"
        >
          Cancel
        </button>

        <!-- <button
          (click)="deletePlan()"
          *ngIf="isEdit"
          type="button"
          class="blue-line-btn blue-line-ltext h32 pd-l10 pd-r10"
        >
          Delete Plan
        </button> -->

        <button
          (click)="submitPlanForm()"
          type="submit"
          class="blue-btn h32 pd-l20 pd-r20 mr-l20"
        >
          {{ isEdit ? "Edit" : "Add" }}
        </button>
      </div>
    </div>
  </form>
</ng-template>
