import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  modalRef!: BsModalRef;
  modalSub!: any;
  subscription!: Subscription;
  @ViewChild('alert', { static: true }) templateEl!: TemplateRef<any>;
  @Output() close = new EventEmitter<boolean>();
  alertMessage: any = '';
  title: any = '';
  show = false;
  constructor(private modalService: BsModalService,
              private alertService:AlertService) { }

  ngOnInit(): void {

    this.subscription = this.alertService.alertState.subscribe(
      (state: any) => {
        this.initModal();
        this.show = state.show;
        this.alertMessage = state.message;
        this.title = state.title;
      }
    );
  }

  /**
* @description - Function to initialize the modal reference variable
*/
  initModal() {
    this.modalRef = this.modalService.show(this.templateEl, {
      // backdrop: 'static',
      class: 'alert-modal dialog513 modal-dialog-centered modal-dialog-scrollable'
    });
    this.modalSub = this.modalRef.onHidden?.subscribe((val: any) => {
      this.close.emit(true);
    });
  }

  ngOnDestroy(): void {
    this.modalSub.unsubscribe();
    this.subscription.unsubscribe();
  }

}
