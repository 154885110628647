import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from 'src/app/services/alert/alert.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { ProjectScopingService } from 'src/app/services/project-scoping/project-scoping.service';

@Component({
  selector: 'app-reviewer-list',
  templateUrl: './reviewer-list.component.html',
  styleUrls: ['./reviewer-list.component.scss']
})
export class ReviewerListComponent implements OnInit {
  @Input() reviewerArr: any;
  @Input() summaryStr:string = '';
  @Input() projectId:any;
  @Input() is_history: boolean = false;
  @Input() showSummary: boolean = true;
  @Output() edit = new EventEmitter<boolean>();
  @Output() deleteEvnt = new EventEmitter<boolean>();
  userData:any;
  constructor(
    private alertService: AlertService,
    private projectScopingService: ProjectScopingService,
    private loaderService: LoaderService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.getUserDetails();    
  }

  getUserDetails(){
    const json:any = this.localStorageService.getData('userDetails');
    this.userData = JSON.parse(json);
  } 

  editComment(e:any){
    this.edit.emit(e.row.data)
  }

  /**
   * @description - Function to delete the comment
   * @param e - comment data
   */
  async deleteComment(e:any){
    const msg = `Are you sure you want to delete '${e.row.data.comment}'?`;
    const header = 'Delete Comment';
    const res = await this.alertService.confirm(msg, header);
    if (res === true) {
      const data: any = {   
        reviewId: e.row.data.id, 
      };  
      try {
        const response: any = await this.projectScopingService.manageComment(
          data,
          'deleteReview'
        );
        if (response.statusCode === 200) {
          this.deleteEvnt.emit(true);
          this.alertService.show(response.message, 'success');
        } else {
          this.alertService.show(response.message, 'info');
        }
      } catch (e: any) {
        console.log(e);
        this.alertService.show(e.error.message, 'error');
      }
    }
  }

}
