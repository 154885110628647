import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from 'src/app/model/user';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  loginSubject = new Subject<boolean>();
  public isLoggedIn: boolean = false;
  currentUserValue: any;
  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  /**
   * @description - Http call to login user
   * @param userData - user data
   * @returns
   */
  login(userData: any) {
    return this.httpClient
      .post(`${environment.apiUrl}/user/login`, userData)
      .toPromise();
  }

  /**
   * @description - Http call to create new user
   * @param userData - user data
   */
  createNewUser(userData: User) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/admin/manageStaff`, userData, {
        headers: headers,
      })
      .toPromise();
  }
  /**
   * @description - Http call to change password user
   * @param userData - user data
   */
  changePassword(userData: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/user/changePassword`, userData, {
        headers: headers,
      })
      .toPromise();
  }
  /**
   * @description - Http call to forgot password user
   * @param userData - user data
   */
  forgotPassword(userData: any) {
    return this.httpClient
      .post(`${environment.apiUrl}/user/forgot`, userData)
      .toPromise();
  }

  /**
   * @description - Http call to reset password user
   * @param userData - user data
   */
  resetPassword(userData: any) {
    const headers = new HttpHeaders().set('auth', userData.token);
    return this.httpClient
      .post(`${environment.apiUrl}/user/reset`, userData, { headers: headers })
      .toPromise();
  }

  /**
   * @description - Http call to verify email user
   * @param userData - user data
   */
  verifyEmail(userData: any) {
    return this.httpClient
      .post(`${environment.apiUrl}/user/verifyAndActivate`, userData)
      .toPromise();
  }

  /**
   * @description - Http call to get the designations
   */
  getDesignation() {
    return this.httpClient
      .get(`${environment.apiUrl}/user/getDesignations`)
      .toPromise();
  }

  /**
   * @description - Http call to get the user details
   * @param userId - user id
   * @returns
   */
  getUserDetails(userId: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(`${environment.apiUrl}/user/getInfo?id=${userId}`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to create the customer order
   * @param orderData - order data
   * @returns
   */
  createOrder(orderData: any) {
    return this.httpClient
      .post(`${environment.apiUrl}/order/createOrder`, orderData)
      .toPromise();
  }

  /**
   * @description - Function to check if user login or not
   * @returns boolean
   */
  checkIfUserLogin() {
    const status: any = this.localStorageService.getData('isUserLogin');
    if (status === 'true') {
      return true;
    }
    return false;
  }

  /**
   * @description - Get existing logged in user data
   * @returns
   */
  getLoggedInUser() {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(`${environment.apiUrl}/user/getLoggedInUser`, {
        headers: headers,
      })
      .toPromise();
  }
}
