import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }
  

  /**
 * @description - Function to set data at local storage 
 * @param key - key to set the data for
 * @param data - data to be added local storage
 */
  setData(key: string, data: any) {
    localStorage.setItem(key, data);
  }
  length(){
   
   console.log(localStorage.length);
   return localStorage.length;
  }

  /**
   * @description - Function to get the data from local storage
   * @param key - key to retrive data
   */
  getData(key: string) {
    return localStorage.getItem(key);
  }

  /**
   * @description - Function to remove data from local storage
   * @param key - key to remove data for
   */
  removeItem(key: string) {
    localStorage.removeItem(key);
  }
}
