import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class OperationsService {
  okrSort = new Subject<any>();
  okrAdded = new Subject<any>();
  taskModified = new Subject<any>();
  planModified = new Subject<any>();
  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService,
    private datepipe: DatePipe
  ) { }

  /**
   * @description - Http call to add plan
   * @param planData - plan data
   * @param action - action to perform
   * @returns
   */
  managePlan(planData: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/operations/${action}`, planData, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the list of plans
   * @returns
   */
  listPlan(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let url = ``;
    data.currentDate !== '' ? (url = `?currentDate=${data.currentDate}`) : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/operations/myPlans${url}`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the list of user by department
   * @param departmentId - department id
   * @returns
   */
  getUsersByDept(departmentId: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/operations/getUsersByDepartment?departmentId=${departmentId}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get master data
   * @returns
   */
  getMasterData() {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(`${environment.apiUrl}/operations/getMasterData`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Function to format the date string
   * @param dateString - date string
   * @returns
   */
  formatDate(dateString: string) {
    const date = new Date(dateString).toUTCString();
    let formatted_date: any = this.datepipe.transform(date, 'yyyy-MM-dd');
    return formatted_date;
  }

  formatDateTime(dateString: string) {
    const date = new Date(dateString).toUTCString();
    let formatted_date: any = this.datepipe.transform(
      date,
      'yyyy-MM-dd hh:mm:ss'
    );
    return formatted_date;
  }

  /**
   * @description - Function to get the formatted time string
   * @param time_required - time required
   * @returns
   */
  formatTimeRequired(time_required: any) {
    const hours = Math.floor(time_required / 60);
    const minutes = time_required % 60;
    const time_required_str = `${hours} Hrs ${minutes} Minutes`;
    return time_required_str;
  }

  /**
   * @description - Http call to get the plan history
   * @param data - parameter data
   * @returns
   */
  getPlanHistory(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let url = ``;
    data.skipRecord !== '' ? (url = `?skipRecord=${data.skipRecord}`) : ``;
    data.pageSize !== '' ? (url += `&pageSize=${data.pageSize}`) : ``;
    data.startingDate && data.startingDate !== ''
      ? (url += `&startingDate=${data.startingDate}`)
      : ``;
    data.endingDate && data.endingDate !== ''
      ? (url += `&endingDate=${data.endingDate}`)
      : ``;
    if (data.isAdmin >= 0) {
      url += `&isAdmin=${data.isAdmin}`;
    }
    return this.httpClient
      .get(`${environment.apiUrl}/operations/getGeneralPlanningHistory${url}`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to request a leave
   * @param leaveData - leave data
   * @returns
   */
  requestLeave(leaveData: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/operations/requestLeave`, leaveData, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the leave history
   * @param data - parameter data
   * @returns
   */
  getLeaveHistory(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let url = ``;
    data.skipRecord !== '' ? (url += `?skipRecord=${data.skipRecord}`) : ``;
    data.pageSize !== '' ? (url += `&pageSize=${data.pageSize}`) : ``;
    data.startingDate ? (url += `&startingDate=${data.startingDate}`) : ``;
    data.endingDate ? (url += `&endingDate=${data.endingDate}`) : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/operations/getLeaveRequestHistory${url}`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the general assignments
   * @param data - param data
   * @returns
   */
  getAssignments(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let url = ``;
    data.skipRecord !== '' ? (url += `?skipRecord=${data.skipRecord}`) : ``;
    data.pageSize !== '' ? (url += `&pageSize=${data.pageSize}`) : ``;
    data.startingDate ? (url += `&startingDate=${data.startingDate}`) : ``;
    data.endingDate ? (url += `&endingDate=${data.endingDate}`) : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/operations/${action}${url}`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get leave approval list
   * @param data - param data
   * @returns
   */
  getLeaveApproval(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    data.skipRecord !== ''
      ? (params = params.append('skipRecord', data.skipRecord))
      : ``;
    data.pageSize && data.pageSize !== ''
      ? (params = params.append('pageSize', data.pageSize))
      : ``;
    data.sortBy && data.sortBy !== ''
      ? (params = params.append('sortBy', data.sortBy))
      : ``;
    data.searchKeyword && data.searchKeyword !== ''
      ? (params = params.append('searchKeyword', data.searchKeyword))
      : ``;
    data.startingDate && data.startingDate !== ''
      ? (params = params.append('startingDate', data.startingDate))
      : ``;
    data.endingDate && data.endingDate !== ''
      ? (params = params.append('endingDate', data.endingDate))
      : ``;
    data.is_admin !== ''
      ? (params = params.append('is_admin', data.is_admin))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/operations/${action}`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to manage the leave
   * @param leaveData - leave data
   * @returns
   */
  manageLeave(leaveData: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(
        `${environment.apiUrl}/operations/approveOrRejectLeave`,
        leaveData,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to add/edit the okr
   * @param okrData - okr data to add
   * @param action - action add or edit
   * @returns
   */
  manageOkr(okrData: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/operations/${action}`, okrData, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to add/edit the task
   * @param okrData - task data to add
   * @param action - action add or edit
   * @returns
   */
  manageTask(okrData: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/operations/${action}`, okrData, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get my okr list
   * @param data - param data
   * @returns
   */
  getMyOkrList(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let url = ``;
    data.skipRecord !== '' ? (url += `?skipRecord=${data.skipRecord}`) : ``;
    data.pageSize !== '' ? (url += `&pageSize=${data.pageSize}`) : ``;
    data.sortBy && data.sortBy !== '' ? (url += `&sortBy=${data.sortBy}`) : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/operations/getMyOkr${url}`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get my task list
   * @param selectedDate - selectedDate
   * @returns
   */
  getTaskList(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let url = ``;
    data.selectedDate && data.selectedDate !== ''
      ? (url += `?selectedDate=${data.selectedDate}`)
      : ``;
    data.skipRecord !== '' ? (url += `&skipRecord=${data.skipRecord}`) : ``;
    data.pageSize !== '' ? (url += `&pageSize=${data.pageSize}`) : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/operations/myTask${url}`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get my okr history
   * @param data - param data
   * @returns
   */
  getHistory(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    let url = ``;
    if (data.skipRecord >= 0) {
      params = params.append('skipRecord', data.skipRecord);
    }
    data.pageSize && data.pageSize !== ''
      ? (params = params.append('pageSize', data.pageSize))
      : ``;

    data.sortBy && data.sortBy !== ''
      ? (params = params.append('sortBy', data.sortBy))
      : ``;

    data.searchKeyword && data.searchKeyword !== ''
      ? (params = params.append('searchKeyword', data.searchKeyword))
      : ``;

    data.startingDate && data.startingDate !== ''
      ? (params = params.append('startingDate', data.startingDate))
      : ``;

    data.endingDate && data.endingDate !== ''
      ? (params = params.append('endingDate', data.endingDate))
      : ``;

    data.currentDate && data.currentDate !== ''
      ? (params = params.append('currentDate', data.currentDate))
      : ``;

    data.type && data.type !== ''
      ? (params = params.append('type', data.type))
      : ``;

    data.nature_id && data.nature_id !== ''
      ? (params = params.append('nature_id', data.nature_id))
      : ``;

    data.departmentType && data.departmentType !== ''
      ? (params = params.append('departmentType', data.departmentType))
      : ``;

    data.employeeId && data.employeeId !== ''
      ? (params = params.append('employeeId', data.employeeId))
      : ``;

    return this.httpClient
      .get(`${environment.apiUrl}/operations/${action}${url}`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get my task history
   * @param data - param data
   * @returns
   */
  getTaskHistory(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let url = ``;
    data.skipRecord !== '' ? (url += `?skipRecord=${data.skipRecord}`) : ``;
    data.pageSize !== '' ? (url += `&pageSize=${data.pageSize}`) : ``;
    data.sortBy && data.sortBy !== '' ? (url += `&sortBy=${data.sortBy}`) : ``;
    data.searchKeyword && data.searchKeyword !== ''
      ? (url += `&searchKeyword=${data.searchKeyword}`)
      : ``;
    data.startingDate && data.startingDate !== ''
      ? (url += `&startingDate=${data.startingDate}`)
      : ``;
    data.endingDate && data.endingDate !== ''
      ? (url += `&endingDate=${data.endingDate}`)
      : ``;
    data.departmentType && data.departmentType !== ''
      ? (url += `&departmentType=${data.departmentType}`)
      : ``;
    data.employeeId && data.employeeId !== ''
      ? (url += `&employeeId=${data.employeeId}`)
      : ``;
    data.type && data.type !== '' ? (url += `&type=${data.type}`) : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/operations/viewTaskHistory${url}`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get employee planning data
   * @param data - param data
   * @returns
   */
  getEmployeePlanning(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let url = ``;
    data.departmentId && data.departmentId !== ''
      ? (url += `?departmentId=${data.departmentId}`)
      : ``;
    data.planOwnerId && data.planOwnerId !== ''
      ? (url += `&planOwnerId=${data.planOwnerId}`)
      : ``;
    data.currentDate && data.currentDate !== ''
      ? (url += `&currentDate=${data.currentDate}`)
      : ``;
    // data.skipRecord !== '' ? (url += `&skipRecord=${data.skipRecord}`) : ``;
    // data.pageSize !== '' ? (url += `&pageSize=${data.pageSize}`) : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/operations/getEmployeePlanningList${url}`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get equipments data
   * @param data - param data
   * @returns
   */
  getEquipment(action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(`${environment.apiUrl}/operations/${action}`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the repair history
   * @param data - param data
   * @returns
   */
  getRepairHistory(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    if (data.skipRecord >= 0) {
      params = params.append('skipRecord', data.skipRecord);
    }
    data.pageSize && data.pageSize !== ''
      ? (params = params.append('pageSize', data.pageSize))
      : ``;

    data.isOperation !== ''
      ? (params = params.append('isOperation', data.isOperation))
      : ``;
    data.startingDate !== ''
      ? (params = params.append('startingDate', data.startingDate))
      : ``;
    data.endingDate && data.endingDate !== ''
      ? (params = params.append('endingDate', data.endingDate))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/operations/repairHistory`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to update equipment status
   * @param data - equipment data
   * @returns
   */
  updateEquipment(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/operations/updateEquipment`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the user suggestion on autocomplete
   * @param searchKeyword - search keyword
   * @returns
   */
  getUserSuggestions(searchKeyword: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/operations/getUserSuggestions?searchKeyword=${searchKeyword}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to add or edit the communication
   * @param data - communication data
   */
  manageCommunication(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/operations/${action}`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get communications data
   * @param data - param data
   * @returns
   */
  getCommunications(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    // data.nature_id && data.nature_id !== ''
    //   ? (url += `?nature_id=${data.nature_id}`)
    //   : ``;
    data.currentDate && data.currentDate !== ''
      ? (params = params.append('currentDate', data.currentDate))
      : ``;
    // data.sortBy && data.sortBy !== '' ? (url += `&sortBy=${data.sortBy}`) : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/operations/communicationsData`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get equipment list
   * @param data - parameter data
   * @returns
   */
  getEquipmentList(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    data.department_id && data.department_id !== ''
      ? (params = params.append('department_id', data.department_id))
      : ``;
    data.skipRecord !== ''
      ? (params = params.append('skipRecord', data.skipRecord))
      : ``;
    data.pageSize && data.pageSize !== ''
      ? (params = params.append('pageSize', data.pageSize))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/admin/getEquipmentList`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the operation dashboard
   * @returns
   */
  getOperationDashboard(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    data.skipRecord !== ''
      ? (params = params.append('skipRecord', data.skipRecord))
      : ``;
    data.pageSize && data.pageSize !== ''
      ? (params = params.append('pageSize', data.pageSize))
      : ``;
    data.skipRec !== ''
      ? (params = params.append('skipRec', data.skipRec))
      : ``;
    data.page && data.page !== ''
      ? (params = params.append('page', data.page))
      : ``;
    data.currentDate && data.currentDate !== ''
      ? (params = params.append('currentDate', data.currentDate))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/operations/getDashboard`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the operation analytics
   * @param data - param data
   * @returns
   */
  getOperationAnalytics(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    data.startingDate !== ''
      ? (params = params.append('startingDate', data.startingDate))
      : ``;
    data.endingDate && data.endingDate !== ''
      ? (params = params.append('endingDate', data.endingDate))
      : ``;
    data.analyticType !== ''
      ? (params = params.append('analyticType', data.analyticType))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/operations/getAnalytics`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the timecard apis data
   * @returns
   */
  getTimecard(action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(`${environment.apiUrl}/operations/${action}`, {
        headers: headers,
      })
      .toPromise();
  }

   /**
   * @description - Http call to get the timecard apis data
   * @returns
   */
    getTimecardApprovalList(data: any) {
      const token: any = this.localStorageService.getData('token');
      const headers = new HttpHeaders({
        auth: `${token}`,
      });
      let params = new HttpParams();
      data.skipRecord !== ''
        ? (params = params.append('skipRecord', data.skipRecord))
        : ``;
      data.pageSize && data.pageSize !== ''
        ? (params = params.append('pageSize', data.pageSize))
        : ``;
      return this.httpClient
        .get(`${environment.apiUrl}/operations/timecardApprovalList`, {
          headers: headers,
          params: params,
        })
        .toPromise();
    }

  /**
   * @description - Http call to get the timecard apis data
   * @returns
   */
  getTimecardHistory(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    data.skipRecord !== ''
      ? (params = params.append('skipRecord', data.skipRecord))
      : ``;
    data.pageSize && data.pageSize !== ''
      ? (params = params.append('pageSize', data.pageSize))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/operations/getTimeCardHistory`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the timecard apis data
   * @returns
   */
  viewTimecard(timeCardId: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    timeCardId ? (params = params.append('timeCardId', timeCardId)) : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/operations/viewTimeCard`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to approve or reject the timecard
   * @param data - timecard data
   * @returns
   */
  manageTimecard(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/operations/${action}`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call for project status
   * @param action - action to perform
   * @returns
   */
  getProjectStatus(action: string, data?: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    data.currentDate !== ''
      ? (params = params.append('currentDate', data.currentDate))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/operations/${action}`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call for project status history
   * @param action - action to perform
   * @returns
   */
  getProjectStatusHistory() {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(`${environment.apiUrl}/operations/getProjectStatusHistory`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to save project status
   * @param data - param request data
   * @param action - action to perform
   * @returns
   */
  saveProjectStatus(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/operations/saveProjectStatus`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the leaves
   * @param data - param data
   * @returns
   */
  getLeaves(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    data.startingDate !== ''
      ? (params = params.append('startingDate', data.startingDate))
      : ``;
    data.endingDate !== ''
      ? (params = params.append('endingDate', data.endingDate))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/operations/getLeaves`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }
}
