import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { OrderConfirmComponent } from 'src/app/modules/shared/order-confirm/order-confirm.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  [x: string]: any;
  // subject for present alert
  private alertSubject = new Subject();
  alertState = this.alertSubject.asObservable();

  // subject for confirm alert
  private confirmAlertSubject = new Subject();
  confirmAlertState = this.confirmAlertSubject.asObservable();

  // subject for to get confirm alert selection
  confirmStatusSubject = new Subject();
  confirmStatusState = this.confirmStatusSubject.asObservable();


  // subject for confirm alert
  private orderConfirmSubject = new Subject();
  orderConfirmState = this.orderConfirmSubject.asObservable();

  // subject for to get confirm alert selection
  orderConfirmStatus = new Subject();
  orderStatusState = this.orderConfirmStatus.asObservable();

  modalRef!: BsModalRef;
  
  constructor(private modalService: BsModalService) {}

  /**
   * @description - Function to present alert
   */
  show(message: string, title:string) {
    const alertState = {
      show:true,
      message,
      title
    }
    this.alertSubject.next(alertState);
  }

  /**
   * @description - Function to hide alert
   */
  hide() {
    this.alertSubject.next(false);
  }

  /**
   * @description - Function to present confirm alert for only logout
   * @param message - message for confirm alert
   * @returns 
   */
   showConfirmAlert(message: string) {
    const alertState = {
      show:true,
      message,
    }
    this.confirmAlertSubject.next(alertState);
    return this.confirmStatusState;
  }

  /**
   * @description - Function to present confirm alert other than logout
   * @param message - message for confirm alert
   * @returns 
   */
  showConfirm(message: string, header: string) {
    const alertState = {
      show:true,
      message,
      header
    }
    this.orderConfirmSubject.next(alertState);
    return this.orderConfirmStatus;
  }

  /**
   * @description - Function to show confirm alert for create bo
   * @param message - message to be displayed on confirm alert
   * @returns 
   */
  confirm(message:string, header:string){
    this.modalRef = this.modalService.show(OrderConfirmComponent,
      { class: 'alert-modal dialog513 modal-dialog-centered modal-dialog-scrollable', initialState: { message: message, header: header }});
    return new Promise<boolean>((resolve, reject) => 
    this.modalRef.content.close.subscribe((result: any) => {
      resolve(result);
      this.modalRef.hide();
     } ));
  }

}
