import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TestingService {
  constructor(
    private localStorageService: LocalStorageService,
    private httpClient: HttpClient
  ) { }

  /**
   * @description - Http call to get the list of testing
   * @returns
   */
  getTestingList(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/testingDepartment/getReceivedOrderBatches?searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the order details for testing
   * @returns
   */
  getTestingOrderDetails(order_batch_id: any, order_id: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/testingDepartment/getReceivedOrderDetails?order_batch_id=${order_batch_id}&order_id=${order_id}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call for approve for cleaning
   * @returns 
   */
  approveClean(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(
        `${environment.apiUrl}/testingDepartment/approveForCleaning`,
        data,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the cleaning batches data
   * @param data - param data
   */
  getCertificationBatches(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/testingDepartment/getBatchesForCertification?searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the certification details
   * @param batchId - batch id
   */
  getCertificationDetails(batchId: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/testingDepartment/getParticularBatchForCertification?batchId=${batchId}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to approve the certification
   * @param data - request data
   * @returns 
   */
  approveCertification(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(
        `${environment.apiUrl}/testingDepartment/approveTheCertification`,
        data,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
  * @description - Http call to approve the certification
  * @param data - request data
  * @returns 
  */
  uploadResult(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(
        `${environment.apiUrl}/testingDepartment/uploadTheResult`,
        data,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get VO testing history
   * @param data - param data
   * @returns 
   */
  getVoTestingHistory(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/testingDepartment/getVOTestingHistory?searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get VO testing history
   * @param data - param data
   * @returns 
   */
  getCertificationHistory(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/testingDepartment/certificationTestingHistory?searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
  * @description - Http call to get recieved product list
  * @returns
  */
  getRecievedList(data: any) {
    let url = '';
    if (data.canisterSubTypeId) {
      url = `?productId=${data.productId}&orderId=${data.orderId}&canisterSubTypeId=${data.canisterSubTypeId}`;
    } else if (data.manifoldSubTypeId) {
      url = `?productId=${data.productId}&orderId=${data.orderId}&manifoldsSubTypeId=${data.manifoldSubTypeId}`;
    } else {
      url = `?productId=${data.productId}&orderId=${data.orderId}`;
    }
    console.log(url);
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/testingDepartment/listAllReceived${url}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to add certificate
   * @param data - certificate document data
   * @returns 
   */
  addCertificate(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(
        `${environment.apiUrl}/airLogistics/addCertificate`,
        data,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to upload other documents
   * @param data - certificate document data
   * @returns 
   */
  uploadOtherDocuments(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(
        `${environment.apiUrl}/airLogistics/uploadOtherDocuments`,
        data,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

}
