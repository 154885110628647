import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { NotFoundComponent } from './modules/not-found/not-found/not-found.component';
import { TermsConditionsComponent } from './modules/terms-conditions/terms-conditions.component';
import { AuthGuardService } from './services/authGuard/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/order-management/order-processing'
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'operations',
    loadChildren: () => import('./modules/operation/operation.module').then((m) => m.OperationModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'project-scoping-&-management',
    loadChildren: () => import('./modules/project-scoping/project-scoping.module').then((m) => m.ProjectScopingModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'order-shipment-form',
    loadChildren: () => import('./modules/order-shipment-form/order-shipment-form.module').then((m) => m.OrderShipmentFormModule),
  },
  {
    path: 'questionaire-form',
    loadChildren: () => import('./modules/questionaire-form/questionaire-form.module').then((m) => m.QuestionaireFormModule),
  },
  {
    path: 'project-scoping-questionaire',
    loadChildren: () => import('./modules/project-scoping-questionaire/project-scoping-questionaire.module').then((m) => m.ProjectScopingQuestionaireModule),
  },
  {
    path: 'quote-summary',
    loadChildren: () => import('./modules/quote-summary/quote-summary.module').then((m) => m.QuoteSummaryModule),
  },
  {
    path: 'user-summary',
    loadChildren: () => import('./modules/summary-form/summary-form.module').then((m) => m.SummaryFormModule),
  },
  {
    path: 'order-management',
    loadChildren: () => import('./modules/project-management/project-management.module').then((m) => m.ProjectManagementModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'customer-management',
    loadChildren: () => import('./modules/air-logistics/air-logistics.module').then((m) => m.AirLogisticsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'stock-management',
    loadChildren: () => import('./modules/sample-control/sample-control.module').then((m) => m.SampleControlModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'accounts',
    loadChildren: () => import('./modules/accounts/accounts.module').then((m) => m.AccountsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'testing',
    loadChildren: () => import('./modules/testing/testing.module').then((m) => m.TestingModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'terms-conditions',
    pathMatch: 'full',
    component: TermsConditionsComponent
  },
  {
    path: 'faq',
    loadChildren: () => import('./modules/faq/faq.module').then((m) => m.FaqModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'about-us',
    loadChildren: () => import('./modules/about-us/about-us.module').then((m) => m.AboutUsModule),
    canActivate: [AuthGuardService],
  },
  // {
  //   path: 'add-party-form/:id',
  //   loadChildren: () => import('./modules/accounts/accounting/add-party-form/add-party-form.module').then((m) => m.AddPartyFormModule),
  // },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
