import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AlertService } from './services/alert/alert.service';
import { LoaderService } from './services/loader/loader.service';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { UserService } from './services/user/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'TorrentWebsite';
  activeLink!: string;

  constructor(
    private router: Router,
    public userService: UserService,
    private alertService: AlertService,
    private localStorageService: LocalStorageService,    
  ) { }

  ngOnInit(): void {
    if (this.userService.checkIfUserLogin()) {
      // this.checkIfValidRoute();
      this.getLoggedInUser();
    }
  }

  isValidUrl() {
    const excludeArr = [
      'auth',
      'order-shipment-form',
      'about-us',
      'faq',
      'terms-conditions',
      // 'add-party-form'
    ];
    const activeLink = this.router.url.split('/');
    // console.log(this.router.url);
    if (excludeArr.indexOf(activeLink[1]) === -1
    && !this.router.url.includes('order-shipment')
    && !this.router.url.includes('questionaire-form')
    && !this.router.url.includes('user-summary')
    && !this.router.url.includes('quote-summary')
    && !this.router.url.includes('project-scoping-questionaire')) {
      return true;
    }
    return false;
  }

  /**
   * @description - Function to get the logged in user details
   */
  async getLoggedInUser() {
    try {
      const response: any = await this.userService.getLoggedInUser();
      if (response.statusCode === 401
        && response.message === 'Session expires. Please login again') {
        this.logoutUser();
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * @description - Function to logout the user
   */
  logoutUser() {
    this.localStorageService.removeItem('isUserLogin');
    this.localStorageService.removeItem('userDetails');
    this.localStorageService.removeItem('token');
    this.router.navigate(['/auth/login']);
  }

  /**
   * @description - Function to check if rendered route valid or not for logged in user role
   */
  checkIfValidRoute(){
    let isValidRoute = false;
    let validUrlArr = [
      "/operations/operation-dashboard",
      "/operations/project-pipeline",
      "/operations/project-status",
      "/operations/daily-planning-and-OKR",
      "/operations/communications",
      "/operations/general-assignments",
      "/operations/equipment-status",
      "/operations/my-analytics",
      "/operations/leave-management",
      "/operations/timecard",
    ];
    const userData:any = this.localStorageService.getData('userDetails');
    const userPermissionArr = JSON.parse(userData).user_permission_array;
    userPermissionArr.map((permission:any)=>{
      permission.module_link = `/${permission.module_name.replace(/\s+/g, '-').toLowerCase()}`;
      permission.submodule_link = `${permission.module_link}/${permission.sub_module_name
        .replace(/\s+/g, '-')
        .toLowerCase()}`;
      validUrlArr.push(permission.module_link, permission.submodule_link);
    });

    console.log(validUrlArr, this.router.url);
    // this.router.events
    //       .subscribe(
    //         (event: NavigationEvent) => {
    //           if(event instanceof NavigationStart) {
    //             console.log(event.url);
    //           }
    //         });

    if(validUrlArr.includes(this.router.url)){
      isValidRoute = true;
    }

    console.log(isValidRoute);
    
  }

}
