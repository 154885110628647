<!-- Cell Details Modal -->
<ng-template #cellDetails>
    <div class="modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Cell Details</h5>
                <button type="button" class="close m-close-btn" data-dismiss="modal" (click)="modalRef.hide()">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.93724 5.64824L11.8872 0.698242L13.3012 2.11224L8.35124 7.06224L13.3012 12.0122L11.8872 13.4262L6.93724 8.47624L1.98724 13.4262L0.573242 12.0122L5.52324 7.06224L0.573242 2.11224L1.98724 0.698242L6.93724 5.64824Z"
                            fill="white" />
                    </svg>
                </button>
            </div>
            <div class="modal-body cell-d-body text-center p-5" *ngIf="productArr && productArr.length === 0">
                <h5> No products found!</h5>
            </div>
            <div class="modal-body cell-d-body" *ngIf="productArr && productArr.length > 0">
                <div class="cell-d-info">
                    <h5 class="mr-b10">
                        {{
                           cellProduct.product_type_id === 1 ? 'Canister' : '' 
                        }}
                        {{ 
                           cellProduct.product_type_id === 2 ? 'Manifold' : ''
                        }} 
                        {{
                           cellProduct.product_type_id === 3 ? 'Equipment' : ''
                        }} Type : 
                        <span>{{cellProduct.name}}</span>
                    </h5>
                    <h5>Order Type : <span>{{productType | titlecase}}</span></h5>
                    <div class="cus-b-head-table table-responsive mr-t20">
                        <table>
                            <thead>
                                <tr>
                                    <th width="20">No</th>
                                    <th>Barcode</th>
                                    <th>Status</th>
                                    <th *ngIf="module === 'testing'">Testing Date</th>
                                    <th *ngIf="module === 'testing'">Testing Person</th>
                                    <th *ngIf="module === 'sample'">Received Date</th>
                                    <th *ngIf="module === 'sample'">Receiving Person</th>
                                    <th width="60" class="text-center" *ngIf="showDelete">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let product of productArr; let i = index">
                                    <td>{{i + 1}}</td>
                                    <td>{{product.barcode}}</td>
                                    <td>{{product.status | titlecase}}</td>
                                    <td 
                                    class="text-center" 
                                    *ngIf="module === 'AL' && showDelete"
                                    (click)="deleteProduct(product.id)">
                                        <button type="button" class="blue-btn edit-btn">
                                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.333 3.49967H13.6663V4.83301H12.333V13.4997C12.333 13.6765 12.2628 13.8461 12.1377 13.9711C12.0127 14.0961 11.8432 14.1663 11.6663 14.1663H2.33301C2.1562 14.1663 1.98663 14.0961 1.8616 13.9711C1.73658 13.8461 1.66634 13.6765 1.66634 13.4997V4.83301H0.333008V3.49967H3.66634V1.49967C3.66634 1.32286 3.73658 1.15329 3.8616 1.02827C3.98663 0.903246 4.1562 0.833008 4.33301 0.833008H9.66634C9.84315 0.833008 10.0127 0.903246 10.1377 1.02827C10.2628 1.15329 10.333 1.32286 10.333 1.49967V3.49967ZM10.9997 4.83301H2.99967V12.833H10.9997V4.83301ZM4.99967 6.83301H6.33301V10.833H4.99967V6.83301ZM7.66634 6.83301H8.99967V10.833H7.66634V6.83301ZM4.99967 2.16634V3.49967H8.99967V2.16634H4.99967Z"
                                                    fill="white" />
                                            </svg>
                                        </button>
                                    </td>
                                    <td *ngIf="module === 'testing'">    
                                        {{ product.testing_date | date : 'MM-dd-yyyy' }}
                                    </td>
                                    <td *ngIf="module === 'testing'">
                                        {{ product.testing_person }}
                                    </td>
                                    <td *ngIf="module === 'sample'">    
                                        {{ product.received_date | date : 'MM-dd-yyyy' }}
                                    </td>
                                    <td *ngIf="module === 'sample'">
                                        {{ product.receiving_person }}
                                    </td>
                                    <td 
                                    class="text-center" 
                                    *ngIf="module === 'sample' && showDelete"
                                    (click)="deleteSampleProduct(product.id)">
                                        <button type="button" class="blue-btn edit-btn">
                                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.333 3.49967H13.6663V4.83301H12.333V13.4997C12.333 13.6765 12.2628 13.8461 12.1377 13.9711C12.0127 14.0961 11.8432 14.1663 11.6663 14.1663H2.33301C2.1562 14.1663 1.98663 14.0961 1.8616 13.9711C1.73658 13.8461 1.66634 13.6765 1.66634 13.4997V4.83301H0.333008V3.49967H3.66634V1.49967C3.66634 1.32286 3.73658 1.15329 3.8616 1.02827C3.98663 0.903246 4.1562 0.833008 4.33301 0.833008H9.66634C9.84315 0.833008 10.0127 0.903246 10.1377 1.02827C10.2628 1.15329 10.333 1.32286 10.333 1.49967V3.49967ZM10.9997 4.83301H2.99967V12.833H10.9997V4.83301ZM4.99967 6.83301H6.33301V10.833H4.99967V6.83301ZM7.66634 6.83301H8.99967V10.833H7.66634V6.83301ZM4.99967 2.16634V3.49967H8.99967V2.16634H4.99967Z"
                                                    fill="white" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
