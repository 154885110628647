import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'app-confirm-alert',
  templateUrl: './confirm-alert.component.html',
  styleUrls: ['./confirm-alert.component.scss'],
})
export class ConfirmAlertComponent implements OnInit, OnDestroy {
  modalRef!: BsModalRef;
  modalSub!: any;
  subscription!: Subscription;
  status!: Subscription;
  @ViewChild('confirmAlert', { static: true }) templateEl!: TemplateRef<any>;
  @Output() close = new EventEmitter<boolean>();
  alertMessage: any = '';
  show = false;
  constructor(
    private modalService: BsModalService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.subscription = this.alertService.confirmAlertState.subscribe(
      (state: any) => {
        this.initModal();
        this.show = state.show;
        this.alertMessage = state.message;
      }
    );
  }

  /**
   * @description - Function to initialize the modal reference variable
   */
  initModal() {
    this.modalRef = this.modalService.show(this.templateEl, {
      class:
        'alert-modal dialog513 modal-dialog-centered modal-dialog-scrollable',
    });
    this.modalSub = this.modalRef.onHidden?.subscribe((val: any) => {
      this.close.emit(true);
    });
  }

  hide() {
    this.modalRef.hide();
  }

  ngOnDestroy(): void {
    // this.modalSub.unsubscribe();
    this.subscription.unsubscribe();
    // this.alertService.confirmStatusSubject.next();
  }

  /**
   * @description - Function to emit true if yes selected
   */
  yes() {
    this.alertService.confirmStatusSubject.next(true);
    this.modalRef.hide();
  }

  /**
   * @description - Function to emit true if yes selected
   */
  cancel() {
    this.alertService.confirmStatusSubject.next(false);
    this.modalRef.hide();
  }
}
