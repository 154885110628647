import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loaderSubject = new Subject();
  loaderState = this.loaderSubject.asObservable();

  constructor() {}

  /**
   * @description - Function to present loader and push true in subject
   */
  show() {
    this.loaderSubject.next(true);
  }

  /**
   * @description - Function to hide loader and push false in subject
   */
  hide() {
    this.loaderSubject.next(false);
  }
}
