import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService
  ) { }

  /**
   * @description - Http call to get the user list
   * @returns
   */
  getUserList(data: any) {
    const userData: any = this.localStorageService.getData('userDetails');
    const shopId: Number = JSON.parse(userData).shop_id;
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    const url = `${environment.apiUrl}/admin/getUserListForManagement?shopId=${shopId}&searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}`;
    return this.httpClient
      .get(url, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to add user permission
   */
  async addPermission(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/admin/updateUserPermission`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the module list
   * @returns
   */
  getModuleList() {
    return this.httpClient
      .get(`${environment.apiUrl}/admin/getModuleList`)
      .toPromise();
  }

  /**
   * @description - Http call to get history for particular module based in module id
   * @param submoduleId - module id
   * @param data - param data
   * @returns
   */
  getHistory(submoduleId: number, data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/order/getHistory?submoduleId=${submoduleId}&searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to download a file from url
   * @param url - pdf url
   * @returns
   */
  download(url: string) {
    const downloadUrl = `${environment.baseUrl}${url}`;
    return this.httpClient
      .get(downloadUrl, {
        responseType: 'blob',
      })
      .toPromise();
  }

  /**
   * @description - Http call to update equipment status
   * @param data - equipment data
   * @returns
   */
  manageEquipment(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/${action}`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the leave approval list data
   * @params data - request data
   */
  getLeaveApproval(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let url = ``;
    if (data.skipRecord >= 0) {
      url += `?skipRecord=${data.skipRecord}`;
    }
    data.pageSize && data.pageSize !== ''
      ? (url += `&pageSize=${data.pageSize}`)
      : ``;

    data.startingDate && data.startingDate !== ''
      ? (url += `?startingDate=${data.startingDate}`)
      : ``;
    data.endingDate && data.endingDate !== ''
      ? (url += `&endingDate=${data.endingDate}`)
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/admin/${action}${url}`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to manage the leave
   * @param leaveData - leave data
   * @returns
   */
  manageLeave(leaveData: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/admin/approveOrRejectLeave`, leaveData, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to manage the task
   * @param taskData - task data
   * @returns
   */
  manageTask(taskData: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/admin/manageMasterTaskTypeList`, taskData, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to update the task status
   * @param taskData - task data to update
   * @returns
   */
  updateTask(taskData: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .put(`${environment.apiUrl}/admin/manageMasterTaskTypeList`, taskData, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the task list
   */
  getTaskList(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    if (data.skipRecord >= 0) {
      params = params.append('skipRecord', data.skipRecord);
    }
    data.pageSize && data.pageSize !== ''
      ? (params = params.append('pageSize', data.pageSize))
      : ``;
    data.searchKeyword && data.searchKeyword !== ''
      ? (params = params.append('searchKeyword', data.searchKeyword))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/admin/manageMasterTaskTypeList`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to add or edit the communication
   * @param data - communication data
   */
  manageCommunication(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/admin/${action}`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get analytics for admin
   * @param data - param data
   * @returns
   */
  getAnalytics(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    data.userId && data.userId !== ''
      ? (params = params.append('userId', data.userId))
      : ``;
    data.startingDate && data.startingDate !== ''
      ? (params = params.append('startingDate', data.startingDate))
      : ``;
    data.endingDate && data.endingDate !== ''
      ? (params = params.append('endingDate', data.endingDate))
      : ``;
    data.testType && data.testType !== ''
      ? (params = params.append('testType', data.testType))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/admin/getAnalytics`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the admin time card approval list
   * @param action
   * @returns
   */
  getTimeCard(action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(`${environment.apiUrl}/admin/${action}`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the timecard apis data
   * @returns
   */
  getTimecardApprovalList(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    data.skipRecord !== ''
      ? (params = params.append('skipRecord', data.skipRecord))
      : ``;
    data.pageSize && data.pageSize !== ''
      ? (params = params.append('pageSize', data.pageSize))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/admin/timecardApprovalList`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the timecard apis data
   * @returns
   */
  getTimecardHistory(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    data.skipRecord !== ''
      ? (params = params.append('skipRecord', data.skipRecord))
      : ``;
    data.pageSize && data.pageSize !== ''
      ? (params = params.append('pageSize', data.pageSize))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/admin/timecardApprovalHistory`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to approve or reject the timecard
   * @param data - timecard data
   * @returns
   */
  manageTimecard(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/admin/${action}`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to add or delete file
   * @param data - file data
   * @param action - url action
   * @returns
   */
  manageRepository(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/admin/${action}`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the repository list
   * @param action
   * @returns
   */
  getRepository(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    if (data.skipRecord >= 0) {
      params = params.append('skipRecord', data.skipRecord);
    }
    data.pageSize && data.pageSize !== ''
      ? (params = params.append('pageSize', data.pageSize))
      : ``;
    data.searchKeyword && data.searchKeyword !== ''
      ? (params = params.append('searchKeyword', data.searchKeyword))
      : ``;
    data.sortBy && data.sortBy !== ''
      ? (params = params.append('sortBy', data.sortBy))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/admin/${action}`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the quote approval list
   * @param data
   * @returns
   */
  getQuoteApprovalList(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    if (data.skipRecord >= 0) {
      params = params.append('skipRecord', data.skipRecord);
    }
    data.pageSize && data.pageSize !== ''
      ? (params = params.append('pageSize', data.pageSize))
      : ``;
    data.searchKeyword && data.searchKeyword !== ''
      ? (params = params.append('searchKeyword', data.searchKeyword))
      : ``;
    data.sortBy && data.sortBy !== ''
      ? (params = params.append('sortBy', data.sortBy))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/admin/${action}`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to accept or reject the quote
   * @returns
   */
  acceptOrRejectQuote(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/admin/approveOrRejectQuote`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to add, edit, delete comment
   * @param data - comment data
   * @param action - action to perform
   * @returns
   */
  manageTemplate(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/admin/${action}`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get reviewers list
   * @param projectId - project id
   * @returns
   */
  getTemplateList(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    if (data.skipRecord >= 0) {
      params = params.append('skipRecord', data.skipRecord);
    }
    data.pageSize && data.pageSize !== ''
      ? (params = params.append('pageSize', data.pageSize))
      : ``;
    data.searchKeyword && data.searchKeyword !== ''
      ? (params = params.append('searchKeyword', data.searchKeyword))
      : ``;
    data.sortBy && data.sortBy !== ''
      ? (params = params.append('sortBy', data.sortBy))
      : ``;
    data.is_follow_up && data.is_follow_up !== ''
      ? (params = params.append('is_follow_up', data.is_follow_up))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/admin/listTemplates`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to add party
   * @param data - comment data
   * @param action - action to perform
   * @returns
   */
  addParty(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/admin/addParty`, data, {
        headers: headers,
      })
      .toPromise();
  }
  /**
  * @description - Http call to get the party master data
  * @returns 
  */
  getPartyList(keyword: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient.get(`${environment.apiUrl}/admin/getParties?searchKeyword=${keyword}`, { headers: headers }).toPromise();
  }

  /**
* @description - Http call to get the list of accounting order list
* @param data - parameter data
* @returns
*/
  getAccountDetails(partyId: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    const url = `${environment.apiUrl}/admin/getAccountDetails/${partyId}`;
    return this.httpClient
      .get(url, {
        headers: headers,
      })
      .toPromise();
  }

  /**
  * @description - Http call to get the list of accounting order list
  * @param data - parameter data
  * @returns
  */
  getAccountingList(data: any) {
    const token: any = this.localStorageService.getData('token');
    const userDetails: any = this.localStorageService.getData('userDetails');
    const shopId: Number = JSON.parse(userDetails).shop_id;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    const url = `${environment.apiUrl}/admin/accountListWithSearch?searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}&shopId=${shopId}`;
    return this.httpClient
      .get(url, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the list of account History
   * @param data - parameter data
   * @returns
   */
  getAccountHistory(data: any) {
    const token: any = this.localStorageService.getData('token');
    const userDetails: any = this.localStorageService.getData('userDetails');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    const url = `${environment.apiUrl}/admin/accountHistoryWithSearch?searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}&shopId=${userDetails.shop_id}`;
    return this.httpClient
      .get(url, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to markPartyAsPaid
   * @param data - comment data
   * @param action - action to perform
   * @returns
   */
  addPartyPayment(request: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/admin/addPartyPayment`, request, {
        headers: headers,
      })
      .toPromise();
  }

  // Order  Management start
  /**
   * @description - Http call to add party
   * @param data - comment data
   * @param action - action to perform
   * @returns
   */
  createOrder(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/order/createUpdateOrder`, data, {
        headers: headers,
      })
      .toPromise();
  }
  // /**
  // * @description - Http call to get the party master data
  // * @returns 
  // */
  // getOrderList(keyword: string) {
  //   const token: any = this.localStorageService.getData('token');
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     auth: `${token}`,
  //   });
  //   return this.httpClient.get(`${environment.apiUrl}/order/orderListWithSearch?searchKeyword=${keyword}`, { headers: headers }).toPromise();
  // }

  /**
* @description - Http call to get the list of accounting order list
* @param data - parameter data
* @returns
*/
  getOrderDetails(partyId: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    const url = `${environment.apiUrl}/order/getOrderDetails/${partyId}`;
    return this.httpClient
      .get(url, {
        headers: headers,
      })
      .toPromise();
  }

  /**
  * @description - Http call to get the list of accounting order list
  * @param data - parameter data
  * @returns
  */
  getOrderList(data: any) {
    const token: any = this.localStorageService.getData('token');
    const userDetails: any = this.localStorageService.getData('userDetails');
    const shopId: Number = JSON.parse(userDetails).shop_id;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    const url = `${environment.apiUrl}/order/orderListWithSearch?searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}&shopId=${shopId}`;
    return this.httpClient
      .get(url, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the list of account History
   * @param data - parameter data
   * @returns
   */
  getOrderHistory(data: any) {
    const token: any = this.localStorageService.getData('token');
    const userDetails: any = this.localStorageService.getData('userDetails');
    const shopId: Number = JSON.parse(userDetails).shop_id;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    const url = `${environment.apiUrl}/order/orderHistoryWithSearch?searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}&shopId=${shopId}`;
    return this.httpClient
      .get(url, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to markPartyAsPaid
   * @param data - comment data
   * @param action - action to perform
   * @returns
   */
  addOrderPayment(request: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/order/addCustomerPayment`, request, {
        headers: headers,
      })
      .toPromise();
  }
  /**
  * @description - Http call to get the getProductsByCategory
  * @returns 
  */
  getProductsByCategory(productId: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient.get(`${environment.apiUrl}/order/getProductsByCategory?productId=${productId}`, { headers: headers }).toPromise();
  }
}
