<ng-template #confirmAlert>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Logout</h5>
        <button type="button" class="close m-close-btn" data-dismiss="modal" (click)="hide()">
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.43724 5.09453L11.3872 0.144531L12.8012 1.55853L7.85124 6.50853L12.8012 11.4585L11.3872 12.8725L6.43724 7.92253L1.48724 12.8725L0.0732422 11.4585L5.02324 6.50853L0.0732422 1.55853L1.48724 0.144531L6.43724 5.09453Z"
                    fill="white" />
            </svg>
        </button>
    </div>
    <div class="modal-body alert-msg-body pd0">
        <h5 class="alert-msg mr-t30 mr-b50 text-center">
           {{alertMessage}}
        </h5>
        <div class="a-m-btn text-right">
            <button (click)="yes()" type="button" class="blue-btn h32 pd-l40 pd-r40 mr-r10">
                Yes
            </button>

            <button (click)="cancel()" type="button" class="blue-btn h32 pd-l40 pd-r40" data-dismiss="modal">
                Cancel
            </button>
        </div>
    </div>
</ng-template>