import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  urlArr:any = []; 
  constructor(private route:Router, private activeRoute:ActivatedRoute) { }

  ngOnInit(): void {
    const urlArr = this.route.url.split('/');
    // console.log(urlArr);

    let obj:any = {};
    urlArr.map((current:any, index: any, array:any)=>{      
      // console.log(array);
      // if (accessForAll.includes(array[index - 1])) {
      //   if (current !== '' && index < 5) {
      //     // console.log(index);

      //     obj = {
      //       link: index > 1 ? (index > 2 ? (index > 3 ? `/${array[index - 3]}/${array[index - 2]}/${array[index - 1]}/${current}` : `/${array[index - 2]}/${array[index - 1]}/${current}`) : `/${array[index - 1]}/${current}`) : `/${current}`,
      //       menuName: current.replace(/-/g, ' ')
      //     };
      //     // console.log(obj);
      //     this.urlArr.push(obj);
      //     // console.log(this.urlArr[1]);
      //   }
      // } else if (current !== '' && index < 4) {
      //   // console.log(index);

      //   obj = {
      //     link: index > 1 ? (index > 2 ? `/${array[index - 2]}/${array[index - 1]}/${current}` : `/${array[index - 1]}/${current}`) : `/${current}`,
      //     // link: index > 1 ? `/${array[index - 1]}/${current}` : `/${current}`,
      //     menuName: current.replace(/-/g, ' ')
      //   };
      //   // console.log(obj);
      //   this.urlArr.push(obj);
      //   // console.log(this.urlArr[1]);
      // }
      if(current !== '' && index < 4){
        // console.log(index);
        
        obj = {
          link: index > 1 ? `/${array[index-1]}/${current}` : `/${current}`,
          menuName: current.replace(/-/g, ' ')
        };
        // console.log(obj);
        this.urlArr.push(obj);
        // console.log(this.urlArr[1]);
      }   
    });
    // console.log(this.urlArr);
  }

}
