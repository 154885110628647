import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectScopingService {
  commentEvent = new Subject<any>();
  questionaireEvent = new Subject<any>();
  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService
  ) { }

  /**
   * @description - Http call to get the questions list master data for questionaire form
   * @returns
   */
  getQuestions() {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(`${environment.apiUrl}/projectScoping/getQuestions`)
      .toPromise();
  }

  /**
   * @description - Http call to get the master data for questionaire form
   * @returns
   */
  getQuestionaireMasterData() {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(`${environment.apiUrl}/projectScoping/getMasterData`)
      .toPromise();
  }

  /**
   * @description - Http call to send questionaire email
   * @param data
   * @returns
   */
  sendQuestionaire(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/projectScoping/createQuestionaire`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to send questionaire email
   * @param data
   * @returns
   */
  generateLink(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/projectScoping/generateProjectLink`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to send questionaire email
   * @param data
   * @returns
   */
  sendLinkEmail(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/projectScoping/sendLinkEmail`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to send questionaire email
   * @param data
   * @returns
   */
  submitQuestionaire(data: any, token: string) {
    // const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/projectScoping/submitQuestionaire`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to create the project scoping
   * @param data - request data
   * @returns
   */
  createProject(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/projectScoping/createProject`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to send questionaire email
   * @param data
   * @returns
   */
  approveQuestionaire(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(
        `${environment.apiUrl}/projectScoping/approveProjectScoping`,
        data,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the list
   * @param data - parameter data
   * @param action - api url action
   * @returns
   */
  getQuestionaireList(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    data.skipRecord !== ''
      ? (params = params.append('skipRecord', data.skipRecord))
      : ``;
    data.pageSize && data.pageSize !== ''
      ? (params = params.append('pageSize', data.pageSize))
      : ``;

    data.skipPipelineRecord !== ''
      ? (params = params.append('skipPipelineRecord', data.skipPipelineRecord))
      : ``;
    data.pipelinePageSize && data.pipelinePageSize !== ''
      ? (params = params.append('pipelinePageSize', data.pipelinePageSize))
      : ``;
    data.sortBy && data.sortBy !== ''
      ? (params = params.append('sortBy', data.sortBy))
      : ``;
    data.currentDate && data.currentDate !== ''
      ? (params = params.append('currentDate', data.currentDate))
      : ``;

    data.searchKeyword && data.searchKeyword !== ''
      ? (params = params.append('searchKeyword', data.searchKeyword))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/projectScoping/${action}`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the questionaire details
   * @param projectId - project id
   * @returns
   */
  getQuestionaireDetails(projectId: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    projectId !== '' ? (params = params.append('projectId', projectId)) : '';
    return this.httpClient
      .get(`${environment.apiUrl}/projectScoping/getQuestionaireDetails`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the summary details
   * @param projectId - project id
   * @returns
   */
  getSummaryDetails(token: any) {
    // const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    // let params = new HttpParams();
    // projectId !== '' ? (params = params.append('projectId', projectId)) : '';
    return this.httpClient
      .get(`${environment.apiUrl}/projectScoping/getSummaryDetails`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to create the summary for project
   * @param data - summary data
   * @returns
   */
  createSummary(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/projectScoping/${action}`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to accept or reject summary
   * @param data - param data
   * @param token - token string
   * @returns
   */
  acceptOrReject(data: any, token: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(
        `${environment.apiUrl}/projectScoping/approveOrRejectSummary`,
        data,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the summary
   * @param data - param data
   * @param action - action to get summary or history
   * @returns
   */
  getSummary(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    data.skipRecord !== ''
      ? (params = params.append('skipRecord', data.skipRecord))
      : ``;
    data.pageSize && data.pageSize !== ''
      ? (params = params.append('pageSize', data.pageSize))
      : ``;
    data.sortBy && data.sortBy !== ''
      ? (params = params.append('sortBy', data.sortBy))
      : ``;
    data.searchKeyword && data.searchKeyword !== ''
      ? (params = params.append('searchKeyword', data.searchKeyword))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/projectScoping/${action}`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to attach quote
   * @param data - file data
   * @param action - action to perform
   * @returns
   */
  manageQuote(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/projectScoping/${action}`, data, {
        headers: headers,
      })
      .toPromise();
  }

  attachDocuments(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/admin/${action}`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the quote details
   * @param projectId - project id
   * @returns
   */
  getQuoteDetails(projectId: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    projectId !== '' ? (params = params.append('projectId', projectId)) : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/projectScoping/getDocumentDetails`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to request post action on view created quote
   * @param data - action data
   * @param action - action to perform
   */
  postQuoteAction(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/projectScoping/${action}`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to submit the quote summary
   * @param data
   * @param token
   * @returns
   */
  submitQuote(data: any, token: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/projectScoping/approveOrRejectQuote`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the quote details for email url page
   * @param token - token string
   * @returns
   */
  getQuoteSummary(token: string) {
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(`${environment.apiUrl}/projectScoping/getDocumentUrlDetails`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to add, edit, delete comment
   * @param data - comment data
   * @param action - action to perform
   * @returns
   */
  manageComment(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/projectScoping/${action}`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get reviewers list
   * @param projectId - project id
   * @returns
   */
  getReviewersList(projectId: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    projectId !== '' ? (params = params.append('projectId', projectId)) : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/projectScoping/listReviewers`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to add or delete the secondary email data
   * @param data - email data
   */
  manageSecondaryEmail(data: any, action: string) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/projectScoping/${action}`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the checklist
   * @returns
   */
  getChecklist() {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(`${environment.apiUrl}/projectScoping/listChecklists`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to verify checklist
   * @param data - checklist verification data
   * @returns
   */
  verifyChecklist(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/airLogistics/verifyCheckList`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to send follow up email
   * @param data - project data to send follow up for
   * @returns
   */
  sendFollowUp(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(`${environment.apiUrl}/projectScoping/sendFollowupMail`, data, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the dashboard data
   * @returns
   */
  getDashboard(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    data.startingDate !== ''
      ? (params = params.append('startingDate', data.startingDate))
      : ``;
    data.endingDate && data.endingDate !== ''
      ? (params = params.append('endingDate', data.endingDate))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/projectScoping/getDashboard`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the report type data
   * @param data - parameter data
   * @returns
   */
  getReportType(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    let params = new HttpParams();
    if (data.skipRecord >= 0) {
      params = params.append('skipRecord', data.skipRecord);
    }
    data.pageSize && data.pageSize !== ''
      ? (params = params.append('pageSize', data.pageSize))
      : ``;
    data.startingDate !== ''
      ? (params = params.append('startingDate', data.startingDate))
      : ``;
    data.endingDate && data.endingDate !== ''
      ? (params = params.append('endingDate', data.endingDate))
      : ``;
    data.reportType && data.reportType !== ''
      ? (params = params.append('reportType', data.reportType))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/projectScoping/getReport`, {
        headers: headers,
        params: params,
      })
      .toPromise();
  }

  /**
   * @description - Http call to check if questionaire form already submitted or not
   * @returns
   */
  getProjectInfo(token: any) {
    // const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(`${environment.apiUrl}/projectScoping/getProjectInfo`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to get the checklist
   * @returns
   */
  getProjectSuggesstion(searchKeyword: string) {
    let params = new HttpParams();
    searchKeyword && searchKeyword !== ''
      ? (params = params.append('searchKeyword', searchKeyword))
      : ``;
    return this.httpClient
      .get(`${environment.apiUrl}/projectScoping/getProjectSuggesstion`, {
        params: params,
      })
      .toPromise();
  }
}
