import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { ProjectScopingService } from 'src/app/services/project-scoping/project-scoping.service';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-shared-quote-verify-checklist',
  templateUrl: './shared-quote-verify-checklist.component.html',
  styleUrls: ['./shared-quote-verify-checklist.component.scss'],
})
export class SharedQuoteVerifyChecklistComponent implements OnInit {
  modalRef!: BsModalRef;
  modalSub!: any;
  @Output() close = new EventEmitter<boolean>();
  isFormSubmitted = true;
  @ViewChild('checklist', { static: true }) templateEl!: TemplateRef<any>;
  disableSubmit: boolean = false;
  userData: any;
  checklistForm!: FormGroup;
  @Input() projectDetails: any;
  isVerifyDisable: boolean = true;
  verifyList: any = [];
  rfpList: any = [];
  showListValidation: boolean = false;
  show: boolean = true;
  @Output() proceed: any = new EventEmitter<boolean>();
  today = new Date();
  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private projectScopingService: ProjectScopingService
  ) { }

  ngOnInit(): void {
    this.initModal();
    this.initChecklistForm();
    this.getUserDetails();
    this.getChecklist();
  }

  /**
   * @description - Function to get the product master data
   */
  async getChecklist() {
    try {
      const response: any = await this.projectScopingService.getChecklist();
      if (response.statusCode === 200) {
        this.verifyList = response.result.checklistData;
        this.rfpList = response.result.rfpChecklistData;
        this.verifyList.map((item: any, i: number) => {
          this.checklistForm.addControl(
            `item-${item.id}`,
            new FormControl('', Validators.required)
          );
        });

        this.rfpList.map((item: any, i: number) => {
          this.checklistForm.addControl(
            `item-${item.id}`,
            new FormControl('', Validators.required)
          );
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * @description - Function to get the user details
   */
  getUserDetails() {
    const json: any = this.localStorageService.getData('userDetails');
    this.userData = JSON.parse(json);
  }

  /**
   * @description - Function to initialize the modal reference variable
   */
  initModal() {
    this.modalRef = this.modalService.show(this.templateEl, {
      class: 'modal-dialog-centered checklist-modal',
    });
    this.modalSub = this.modalRef.onHidden?.subscribe((val: any) => {
      this.close.emit(true);
    });
  }

  /**
   * @description - Function to initialize the checklist form
   */
  initChecklistForm() {
    this.checklistForm = this.formBuilder.group({
      comments: ['', [Validators.required, Validators.maxLength(300)]],
    });
  }

  /**
   * @description - Function to verify the checklist
   */
  async verifyChcklist() {
    if (this.checklistForm.valid) {
      const controls = this.checklistForm.controls;
      const data: any = {
        projectId: this.projectDetails.project_id,
        checkListId: 11,
        checkListComment: controls.comments ? controls.comments.value : '',
        checkListDetails: [],
      };
      let obj = {};
      this.verifyList.map((item: any) => {
        if (this.checklistForm.controls[`item-${item.id}`].value) {
          obj = {
            checklistItemId: item.id,
          };
        }
        data.checkListDetails.push(obj);
      });
      try {
        const response: any = await this.projectScopingService.verifyChecklist(
          data
        );
        if (response.statusCode === 200) {
          this.proceed.emit(true);
          this.modalRef.hide();
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      this.isFormSubmitted = false;
    }
  }

  /**
   * @description - Function to set checkbox control value
   * @param event - event data
   * @param controlName - control name
   */
  onCheckboxSelect(event: any, controlName: string) {
    if (event.target.checked) {
      this.checklistForm.get(`item-${controlName}`)?.setValue(true);
      this.isVerifyDisable = false;
      this.isChecklistValid();
    } else {
      this.checklistForm.get(`item-${controlName}`)?.setValue('');
      this.isVerifyDisable = true;
      this.isChecklistValid();
    }
  }

  /**
   * @description - Function to if all items are checked or not
   */
  isChecklistValid() {
    this.verifyList.map((item: any) => {
      if (!this.checklistForm.get(`item-${item.id}`)?.valid) {
        this.showListValidation = true;
      } else {
        this.showListValidation = false;
      }
    });

    this.rfpList.map((item: any) => {
      if (!this.checklistForm.get(`item-${item.id}`)?.valid) {
        this.showListValidation = true;
      } else {
        this.showListValidation = false;
      }
    });
  }

  /**
   * @description - Function to toggle the comments textarea
   * @param event - event data
   */
  toggle(event: any) {
    this.show = !this.show;
    if (!event.target.checked) {
      this.checklistForm.addControl(
        'comments',
        new FormControl('', Validators.required)
      );
    } else {
      this.checklistForm.removeControl('comments');
    }
  }

  getPDF() {
    let HTML_Width: any = $('#verify').width();
    let HTML_Height: any = $('#verify').height();
    let top_left_margin = 35;
    let PDF_Width = HTML_Width + top_left_margin * 2;
    let PDF_Height: any = PDF_Width * 1.2;
    let canvas_image_width = HTML_Width;
    let canvas_image_height = HTML_Height;

    let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    const data: any = document.getElementById('verify');


    html2canvas(data).then((canvas) => {

      let imgData = canvas.toDataURL('image/jpeg', 1.0);
      let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(
        imgData,
        'JPG',
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height
      );

      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height]);
        pdf.addImage(
          imgData,
          'JPG',
          top_left_margin,
          -(PDF_Height * i) + top_left_margin * 4,
          canvas_image_width,
          canvas_image_height
        );
      }
      const pdfName = `Verify_Checklist_Create_Quote.pdf`;
      pdf.save(pdfName);
    });
  }

  /**
   * @description - Function to check all checklist items
   * @param event - event data
   */
  checkAll(event: any) {
    if (event?.target.checked) {
      this.verifyList.map((item: any) => {
        this.checklistForm.get(`item-${item.id}`)?.setValue(true);
      });
      this.isVerifyDisable = false;
      this.isChecklistValid();
    } else {
      this.verifyList.map((item: any) => {
        this.checklistForm.get(`item-${item.id}`)?.setValue('');
      });
      this.isVerifyDisable = true;
      this.isChecklistValid();
    }
  }

  checkAllRfp(event: any) {
    if (event?.target.checked) {
      this.rfpList.map((item: any) => {
        this.checklistForm.get(`item-${item.id}`)?.setValue(true);
      });
      this.isVerifyDisable = false;
      this.isChecklistValid();
    } else {
      this.rfpList.map((item: any) => {
        this.checklistForm.get(`item-${item.id}`)?.setValue('');
      });
      this.isVerifyDisable = true;
      this.isChecklistValid();
    }
  }
}
