import { Component, Input, OnInit } from '@angular/core';
import { registerPalette, currentPalette } from 'devextreme/viz/palette';

@Component({
  selector: 'app-doughchart',
  templateUrl: './doughchart.component.html',
  styleUrls: ['./doughchart.component.scss']
})
export class DoughchartComponent implements OnInit {
  @Input() dataSource: any = [];
  @Input() title: string = '';
  constructor() { }

  ngOnInit(): void {
    const servicePortalPalette = {  
      simpleSet: ['#064584', '#FC4141', '#FCA04C', '#00D2EF', '#5C00A4'], // for dxChart, dxPieChart and dxBarGauge  
      // indicatingSet: ['#90ba58', '#eeba69', '#a37182'], // for dxCircularGauge and dxLinearGauge  
      // gradientSet: ['#78b6d9', '#eeba69'] // for dxVectorMap  
    };  
    registerPalette('servicePortalPalette', servicePortalPalette);  
    currentPalette('servicePortalPalette');  
  }

  customizeTooltip = (arg: any) => ({
    text: `${arg.argumentText} - ${arg.value}`,
  });
}
