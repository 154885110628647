export const menuArr = [
    {
        module_name: 'Admin',
        link: '/admin',
        icon:'assets/images/sidemenu/admin.svg',
        operation_menu:[
            // {
            //     sub_module_name: 'Dashboard',
            //     icon:'/assets/images/sidemenu/dashboard.svg',
            //     link: '/operations/operation-dashboard',
            // },
            // {
            //     sub_module_name: 'Project Pipeline',
            //     icon:'/assets/images/sidemenu/project-pipeline.svg',
            //     link: '/operations/project-pipeline',
            // },
            // {
            //     sub_module_name: 'Project Status',
            //     icon:'/assets/images/sidemenu/project-status.svg',
            //     link: '/operations/project-status',
            // },
            // {
            //     sub_module_name: 'Daily Planning & OKR',
            //     icon:'/assets/images/sidemenu/daily-planning-&-okr.svg',
            //     link: '/operations/daily-planning-and-OKR',
            // },
            // {
            //     sub_module_name: 'Communications',
            //     icon:'/assets/images/sidemenu/communications.svg',
            //     link: '/operations/communications',
            // },
            // {
            //     sub_module_name: 'General Assignments',
            //     icon:'/assets/images/sidemenu/general-assignments.svg',
            //     link: '/operations/general-assignments',
            // },
            {
                sub_module_name: 'Equipment Status',
                icon:'/assets/images/sidemenu/equipment-status.svg',
                link: '/operations/equipment-status',
            },
            // {
            //     sub_module_name: 'Analytics',
            //     icon:'/assets/images/sidemenu/analytics.svg',
            //     link: '/operations/my-analytics',
            // },
            // {
            //     sub_module_name: 'Leave Management',
            //     icon:'/assets/images/sidemenu/leave-management.svg',
            //     link: '/operations/leave-management',
            // },
            // {
            //     sub_module_name: 'Time Card',
            //     icon:'/assets/images/sidemenu/time-card.svg',
            //     link: '/operations/timecard',
            // },           
        ],
        admin_menu:[
            {
                module_name: 'User & Access Management',
                icon:'',
                link: '/admin/user-management',
            },
            {
                module_name: 'Inventory Management',
                icon:'',
                link: '/admin/inventory-management',
            },
            {
                module_name: 'Product Management',
                icon:'',
                link: '/admin/product-management',
            },
            // {
            //     module_name: 'Analytics',
            //     icon:'',
            //     link: '/admin/analytics',
            // },
            // {
            //     module_name: 'Project Status',
            //     icon:'',
            //     link: '/admin/project-status',
            // },
            // {
            //     module_name: 'Communication',
            //     icon:'',
            //     link: '/admin/communication',
            // },
            // {
            //     module_name: 'Equipment List',
            //     icon:'',
            //     link: '/admin/equipment-list',
            // },
            // {
            //     module_name: 'Leave Approval',
            //     icon:'',
            //     link: '/admin/leave-approval',
            // },
            // {
            //     module_name: 'General Planning',
            //     icon:'',
            //     link: '/admin/general-planning',
            // },
            // {
            //     module_name: 'Task List',
            //     icon:'',
            //     link: '/admin/task-list',
            // },
            // {
            //     module_name: 'Time Card Approval',
            //     icon:'',
            //     link: '/admin/timecard-approval',
            // },
            // {
            //     module_name: 'Quote Approval',
            //     icon:'',
            //     link: '/admin/quote-approval',
            // },
            // {
            //     module_name: 'Repository',
            //     icon:'',
            //     link: '/admin/repository',
            // }
        ],
        // project_menu : [
        //     {
        //         sub_module_name: 'Dashboard',
        //         icon:'/assets/images/sidemenu/dashboard.svg',
        //         link: '/project-scoping-&-management/sales-dashboard',
        //     },
        //     {
        //         sub_module_name: 'Send Questionnaire',
        //         icon:'/assets/images/sidemenu/send-questionaire.svg',
        //         link: '/project-scoping-&-management/send-questionaire',
        //     },
        //     {
        //         sub_module_name: 'View Sent Questionnaire',
        //         icon:'/assets/images/sidemenu/view-sent-quetionaire.svg',
        //         link: '/project-scoping-&-management/view-sent-questionnaire',
        //     },
        //     {
        //         sub_module_name: 'View Summary',
        //         icon:'/assets/images/sidemenu/view-summary.svg',
        //         link: '/project-scoping-&-management/view-summary',
        //     },
        //     {
        //         sub_module_name: 'Quote Creation',
        //         icon:'/assets/images/sidemenu/quote-creation.svg',
        //         link: '/project-scoping-&-management/quote-creation',
        //     },
        //     {
        //         sub_module_name: 'View Created Quotes',
        //         icon:'/assets/images/sidemenu/view-created-quotes.svg',
        //         link: '/project-scoping-&-management/view-created-quotes',
        //     },
        //     {
        //         sub_module_name: 'Project Scoping',
        //         icon:'/assets/images/sidemenu/project-scoping.svg',
        //         link: '/project-scoping-&-management/project-scoping',
        //     },
        //     {
        //         sub_module_name: 'Project Pipeline',
        //         icon:'/assets/images/sidemenu/project-pipeline.svg',
        //         link: '/project-scoping-&-management/project-pipeline',
        //     }
        // ]
    }
];