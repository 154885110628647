import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AirLogisticsService } from 'src/app/services/air-logistics/air-logistics.service';
import { SampleControlService } from 'src/app/services/sample-control/sample-control.service';
import { TestingService } from 'src/app/services/testing/testing.service';

@Component({
  selector: 'app-cell-details',
  templateUrl: './cell-details.component.html',
  styleUrls: ['./cell-details.component.scss']
})
export class CellDetailsComponent implements OnInit {
  modalRef!: BsModalRef;
  modalSub!: any;
  @ViewChild('cellDetails', { static: true }) templateEl!: TemplateRef<any>;
  @Output() close = new EventEmitter<boolean>();
  @Output() productDeleted = new EventEmitter<boolean>();
  @Input() orderId: any;
  @Input() productId: any;
  @Input() productType: any;
  @Input() module: any;
  canisterSubTypeId:any;
  manifoldSubTypeId:any;
  productArr : any = [];
  @Input() cellProduct: any;
  @Input() subType:any
  @Input() showDelete:any
  paramData: any;
  constructor(
    private modalService: BsModalService,
    private airService: AirLogisticsService,
    private sampleService: SampleControlService,
    private testingService: TestingService
  ) { }

  ngOnInit(): void {
    // console.log("module ===", this.module);
    this.paramData = {
      orderId: this.orderId,
      productId: this.productId,        
    }
    this.initModal();
    switch(this.productType) {
      case 'canister sample':
        this.canisterSubTypeId = 1;
        this.paramData.canisterSubTypeId = this.canisterSubTypeId;
        this.paramData.subTypeId = this.canisterSubTypeId;
        break;
      case 'canister purge':
        this.canisterSubTypeId = 2;
        this.paramData.canisterSubTypeId = this.canisterSubTypeId;
        this.paramData.subTypeId = this.canisterSubTypeId;
        break;
      case 'manifold standard':
          this.manifoldSubTypeId = 1;
          this.paramData.manifoldSubTypeId = this.manifoldSubTypeId;
          this.paramData.subTypeId = this.manifoldSubTypeId;
          break;
      case 'manifold duplicate':
          this.manifoldSubTypeId = 2;
          this.paramData.manifoldSubTypeId = this.manifoldSubTypeId;
          this.paramData.subTypeId = this.manifoldSubTypeId;
          break;
      default:
        if(this.module === 'AL'){
          this.getCellDetails()
        }else if(this.module === 'sample'){
          this.getSampleList()
        }else{
          // console.log(this.paramData);
          this.getTestingList();
        }
          break;
    }
    if(this.module === 'AL'){
      this.getCellDetails()
    }else if(this.module === 'sample'){
      this.getSampleList()
    }else{
      // console.log(this.paramData);
      this.getTestingList();
    }
  }
  /**
     * @description - Function to initialize the modal reference variable
     */
  initModal() {
    this.modalRef = this.modalService.show(this.templateEl,
      {
        class: 'modal-dialog-centered dashboard-modal'
      });
    this.modalSub = this.modalRef.onHidden?.subscribe((val: any) => {
      this.close.emit(true);
    });

  }

  ngOnDestroy(): void {
    this.modalSub.unsubscribe();
  }

  /**
   * @description - Function to get the allocated product list
   */
  async getCellDetails() {
    try {
      // console.log(this.paramData);
      const response: any = await this.airService.getFulfillList(this.paramData);
      // console.log(response);
      if (response.statusCode === 200) {
        this.productArr = response.result;
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * @description - Function to get the allocated product list
   */
   async getSampleList() {
    try {
      if(this.subType === 'used'){
        this.paramData.isUnused = 0;
      }
      if(this.subType === 'unused'){
        this.paramData.isUnused = 1;
      }
      // console.log(this.paramData);
      const response: any = await this.sampleService.getRecievedList(this.paramData);
      // console.log(response);
      if (response.statusCode === 200) {
        this.productArr = response.result;
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * @description - Function to get the allocated product list
   */
   async getTestingList() {
    try {
      // console.log(this.paramData);
      const response: any = await this.testingService.getRecievedList(this.paramData);
      // console.log(response);
      if (response.statusCode === 200) {
        this.productArr = response.result;
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * @description - Function to delete the product from list
   */
  async deleteProduct(product_id:any){
    try{ 
      const response: any = await this.airService.deleteProduct(product_id);
      // console.log(response);
      if(response.statusCode === 200){
        this.getCellDetails();
        this.modalRef.hide();
        this.productDeleted.emit(true);
      }
    }catch(e){
      console.log(e)
    }
  }

  /**
   * @description - Function to delete the product from list
   */
   async deleteSampleProduct(fulfillmentId:any){
    try{ 
      const data = {
          fulfillmentId: fulfillmentId
      };
      // console.log(data);
      const response: any = await this.sampleService.deleteProduct(data);
      // console.log(response);
      if(response.statusCode === 200){
        this.getCellDetails();
        this.modalRef.hide();
        this.productDeleted.emit(true);
      }
    }catch(e){
      console.log(e)
    }
  }

}
