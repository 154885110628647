<!-- <div id="chart-demo">
    <dx-chart palette="Violet" [dataSource]="countriesInfo">
        <dxi-series 
        *ngFor="let energy of energySources" 
        [valueField]="energy.value" 
        [name]="energy.name">
        </dxi-series>
        <dxo-common-series-settings #seriesSettings argumentField="country" [type]="'line'">
        </dxo-common-series-settings>
        <dxo-margin [bottom]="20"></dxo-margin>
        <dxo-argument-axis [valueMarginsEnabled]="false" discreteAxisDivisionMode="crossLabels">
            <dxo-grid [visible]="true"></dxo-grid>
        </dxo-argument-axis>
        <dxo-legend [visible]="false" verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="bottom">
        </dxo-legend>
       
        <dxo-export [enabled]="false"></dxo-export>
        <dxo-tooltip [enabled]="true"></dxo-tooltip>
    </dx-chart>
</div> -->

<dx-chart id="chart" [dataSource]="dataSource" title="Tests Done">
    <dxi-series argumentField="name"></dxi-series>
    <dxo-argument-axis>
        <dxo-label wordWrap="none" [overlappingBehavior]="'stagger'"></dxo-label>
    </dxo-argument-axis>
    <dxo-tooltip
        [enabled]="true"
        [customizeTooltip]="customizeTooltip"
    >
    </dxo-tooltip>
    <dxo-legend [visible]="false"></dxo-legend>

   
</dx-chart>
<div class="alert alert-primary heading" role="alert">
    <h5>
        Average tests done per day : {{ avgTest ? avgTest : 0 }}
    </h5>
</div>