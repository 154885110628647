<div class="pro-b-head">
  <h5>Daily Planning</h5>
</div>
<div class="pro-details-body">
  <div class="planing-wrap">
    <div class="planing-input-head">
      <div class="row">
        <div class="col-5">
          <div class="static-info inline-form box-input mr-b20">
            <h5 class="mr-r10">Department: </h5>
            <mat-form-field appearance="standard" class="w-100">
              <mat-select
                class="form-control"
                [value]="departmentId"
                (valueChange)="getUsersByDept($event)"
                placeholder="Select Department"
              >
                <mat-option *ngFor="let dept of deptArr" [value]="dept.id">
                  {{ dept.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-5">
          <div class="static-info inline-form box-input mr-b20">
            <h5 class="mr-r10">Employee: </h5>
            <mat-form-field appearance="standard" class="w-100">
              <mat-select
                class="form-control"
                [value]="employeeId"
                placeholder="Select Employee"
                (valueChange)="onUserSelect($event)"
              >
                <mat-option *ngFor="let emp of empArr" [value]="emp.id">
                  {{ emp.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm-2 ml-auto a-plan-btn text-right">
          <button
            (click)="openAddPlan(false)"
            type="button"
            class="blue-btn h32"
          >
            Add Plan
          </button>
        </div>
      </div>

      <div class="accordion mt-3" id="accordionExample">
        <div class="card">
          <div class="card-header" id="firstOne">
            <h2 class="mb-0">
              <button
                class="btn btn-link btn-block text-left"
                type="button"
                data-toggle="collapse"
                data-target="#today"
                aria-expanded="true"
                aria-controls="today"
              >
                Today
                <div class="inline-tab-info">
                  <span
                    [ngClass]="getTotalHrs('today') >= 8 ? '' : 'red-status'"
                  >
                    Total Hrs:
                    {{ getTotalHrs("today") ? getTotalHrs("today") : 0 }}
                  </span>
                  <span
                    >No of Entries:
                    {{
                      planArr && planArr.today ? planArr.today.length : 0
                    }}</span
                  >
                  <h5>
                    Pending:
                    {{
                      getPendingCount("today") ? getPendingCount("today") : 0
                    }}
                  </h5>
                </div>
              </button>
            </h2>
          </div>

          <div
            id="today"
            class="collapse show"
            aria-labelledby="firstOne"
            data-parent="#accordionExample"
          >
            <div class="card-body dp-t-info">
              <div class="d-details-table">
                <div class="cus-b-head-table table-responsive">
                  <table>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Description</th>
                        <th>Added by</th>
                        <th>Time required</th>
                        <th>Status</th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody
                      *ngIf="
                        planArr && planArr.today && planArr.today.length > 0
                      "
                    >
                      <tr *ngFor="let plan of planArr.today; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ plan.description }}</td>
                        <td>{{ plan.added_by }}</td>
                        <td>
                          {{
                            operationService.formatTimeRequired(
                              plan.time_required
                            )
                          }}
                        </td>
                        <td>{{ plan.status }}</td>
                        <td>
                          <div class="action-btn text-center">
                            <img
                              (click)="openAddPlan(true, plan)"
                              src="assets/images/edit-icon.png"
                              alt="edit-icon"
                            />

                            <img
                              (click)="deletePlan(plan)"
                              src="assets/images/delete-icon.png"
                              alt="edit-icon"
                              class="cursor ml-2"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="planArr && planArr.today.length === 0">
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No Plans found</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="secondOne">
            <h2 class="mb-0">
              <button
                class="btn btn-link btn-block text-left"
                type="button"
                data-toggle="collapse"
                data-target="#tomorrow"
                aria-expanded="true"
                aria-controls="tomorrow"
              >
                Tomorrow
                <div class="inline-tab-info">
                  <span
                    [ngClass]="getTotalHrs('tomorrow') >= 8 ? '' : 'red-status'"
                  >
                    Total Hrs:
                    {{ getTotalHrs("tomorrow") ? getTotalHrs("tomorrow") : 0 }}
                  </span>
                  <span
                    >No of Entries:
                    {{
                      planArr && planArr.tomorrow ? planArr.tomorrow.length : 0
                    }}</span
                  >
                  <h5>Pending: {{ getPendingCount("tomorrow") }}</h5>
                </div>
              </button>
            </h2>
          </div>

          <div
            id="tomorrow"
            class="collapse"
            aria-labelledby="secondOne"
            data-parent="#accordionExample"
          >
            <div class="card-body dp-t-info">
              <div class="d-details-table">
                <div class="cus-b-head-table table-responsive">
                  <table>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Description</th>
                        <th>Added by</th>
                        <th>Time required</th>
                        <th>Status</th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody
                      *ngIf="
                        planArr &&
                        planArr.tomorrow &&
                        planArr.tomorrow.length > 0
                      "
                    >
                      <tr *ngFor="let plan of planArr.tomorrow; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ plan.description }}</td>
                        <td>{{ plan.added_by }}</td>
                        <td>
                          {{
                            operationService.formatTimeRequired(
                              plan.time_required
                            )
                          }}
                        </td>
                        <td>{{ plan.status }}</td>
                        <td>
                          <div class="action-btn text-center">
                            <img
                              (click)="openAddPlan(true, plan)"
                              src="assets/images/edit-icon.png"
                              alt="edit-icon"
                            />

                            <img
                              (click)="deletePlan(plan)"
                              src="assets/images/delete-icon.png"
                              alt="edit-icon"
                              class="cursor ml-2"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="planArr && planArr.tomorrow.length === 0">
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No Plans found</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id="secondOne">
            <h2 class="mb-0">
              <button
                class="btn btn-link btn-block text-left"
                type="button"
                data-toggle="collapse"
                data-target="#day_after_tomorrow"
                aria-expanded="true"
                aria-controls="day_after_tomorrow"
              >
                Day After Tomorrow
                <div class="inline-tab-info">
                  <span
                    [ngClass]="
                      getTotalHrs('day_after_tomorrow') >= 8 ? '' : 'red-status'
                    "
                  >
                    Total Hrs:
                    {{
                      getTotalHrs("day_after_tomorrow")
                        ? getTotalHrs("day_after_tomorrow")
                        : 0
                    }}
                  </span>
                  <span
                    >No of Entries:
                    {{
                      planArr && planArr.day_after_tomorrow
                        ? planArr.day_after_tomorrow.length
                        : 0
                    }}</span
                  >
                  <h5>Pending: {{ getPendingCount("day_after_tomorrow") }}</h5>
                </div>
              </button>
            </h2>
          </div>

          <div
            id="day_after_tomorrow"
            class="collapse"
            aria-labelledby="thirdOne"
            data-parent="#accordionExample"
          >
            <div class="card-body dp-t-info">
              <div class="d-details-table">
                <div class="cus-b-head-table table-responsive">
                  <table>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Description</th>
                        <th>Added by</th>
                        <th>Time required</th>
                        <th>Status</th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody
                      *ngIf="
                        planArr &&
                        planArr.day_after_tomorrow &&
                        planArr.day_after_tomorrow.length > 0
                      "
                    >
                      <tr
                        *ngFor="
                          let plan of planArr.day_after_tomorrow;
                          let i = index
                        "
                      >
                        <td>{{ i + 1 }}</td>
                        <td>{{ plan.description }}</td>
                        <td>{{ plan.added_by }}</td>
                        <td>
                          {{
                            operationService.formatTimeRequired(
                              plan.time_required
                            )
                          }}
                        </td>
                        <td>{{ plan.status }}</td>
                        <td>
                          <div class="action-btn text-center">
                            <img
                              (click)="openAddPlan(true, plan)"
                              src="assets/images/edit-icon.png"
                              alt="edit-icon"
                            />

                            <img
                              (click)="deletePlan(plan)"
                              src="assets/images/delete-icon.png"
                              alt="edit-icon"
                              class="cursor ml-2"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody
                      *ngIf="planArr && planArr.day_after_tomorrow.length === 0"
                    >
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No Plans found</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-shared-add-plan 
*ngIf="showAddPlan" 
[isEdit]="isEdit" 
(close)="showAddPlan = false" 
[planData]="planData"
[departmentId]="departmentId" 
[employeeId]="employeeId" 
[isEmployee]="true"
(planEvent)="getEmployeePlanning(true)">
</app-shared-add-plan>